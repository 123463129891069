const Loading = () => {
  return (
    <section className="loader">
      <div className="loading loading03">
        <span style={{ color: "#a4431c" }}>N</span>
        <span style={{ color: "#a4431c" }}>A</span>
        <span style={{ color: "#a4431c" }}>F</span>
        <span style={{ color: "#a4431c" }}>T</span>
        <span style={{ color: "#a4431c" }}>A</span>
        <span style={{ color: "#a4431c" }}>L</span>
        <span style={{ color: "#a4431c" }}>A</span>
        <span style={{ color: "#a4431c" }}>N</span>
        <span style={{ color: " #3d0700" }}>.</span>
        <span style={{ color: " #3d0700" }}>B</span>
        <span style={{ color: " #3d0700" }}>I</span>
        <span style={{ color: " #3d0700" }}>Z</span>
      </div>
    </section>
  );
};

export default Loading;
