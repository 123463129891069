// React
import { useContext } from "react";

// Context
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const HotelCommentCard = ({ comment }) => {
  // GLobal State
  const { setCommentVisible } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  return (
    <div className="hotel-comment-card">
      <div className="personal-area">
        {/* <div className="personal-img">
          <img src={testImg} alt="personalImg" />
        </div> */}
        <div className="personal-info">
          <h4 className="person-name">{comment.review_reviewer_name}</h4>
          <div className="evaluation">
            {Array.from({ length: 5 }, (_, index) => (
              <div
                key={index}
                className={
                  comment.review_star_rating >= index + 1
                    ? "icon selectStar"
                    : "icon"
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                    stroke="#b0b0b0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p className="text">{comment.review_text}</p>
      <div className="btn-area">
        {comment.review_text.split("").length > 200 && (
          <button className="read-more" onClick={() => setCommentVisible(true)}>
            {t("btn.read-more")}...
          </button>
        )}
      </div>
    </div>
  );
};

export default HotelCommentCard;
