import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const DropDown = ({ dropMenuData }) => {
  const drop = useRef(null);
  const { i18n } = useTranslation();

  return (
    <div
      className={`drop-down-area ${dropMenuData !== null ? "isShown" : ""}`}
      style={{ height: dropMenuData && dropMenuData.length * 39 + 5 }}
    >
      <ul className="drop-down" ref={drop}>
        {dropMenuData &&
          dropMenuData.map((item) => (
            <li className="drop-item" key={item.id}>
              <NavLink to={item.url}>
                {item[`page_name_${i18n.language}`]}
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DropDown;
