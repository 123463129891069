// React
import { useCallback, useContext, useEffect, useState } from "react";

// React router Dom
import { Link } from "react-router-dom";

// Axios
import axios from "axios";

// Context
import { MainContext } from "../../utils/MainContext";

// Language
import { useTranslation } from "react-i18next";

// Component
import Loading from "../Loading";

// Default Img
import defaultImg from "../../assets/img/IMG-not-found.svg";

const SuggestHotel = () => {
  // Global State
  const { currentCodeName } = useContext(MainContext);

  // Local State
  const [hotelData, setHotelData] = useState(null);
  const [dateData, setDateData] = useState({ current: "", later: "" });
  const [loading, setLoading] = useState(false);
  // Language
  const { t, i18n } = useTranslation();

  const getHotelData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_HOTEL_RANDOM}?date_from=${
          dateData.current
        }&date_to=${
          dateData.later
        }&standard_guests=${1}&standard_guest_age_1=${18}&minor_guests=${0}&currency_code=${currentCodeName}&locale=${
          i18n.language
        }
`
      )
      .then((res) => {
        setHotelData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        err && setLoading(false);
        // navigate("/error");
      });
  }, [currentCodeName, dateData, i18n.language]);

  // Pick Current Date And Next Day
  useEffect(() => {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    // Bir gün sonrasını hesapla
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate() + 1);

    const nextDayYear = nextDay.getFullYear();
    const nextDayMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
    const nextDayDay = String(nextDay.getDate()).padStart(2, "0");

    const nextDayDateString = `${nextDayYear}-${nextDayMonth}-${nextDayDay}`;

    // Durumu güncelle
    setDateData({ current: currentDateString, later: nextDayDateString });
  }, []);
  // Rendering Data
  useEffect(() => {
    if (
      dateData.current &&
      dateData.later &&
      i18n.language &&
      currentCodeName
    ) {
      getHotelData();
    }
  }, [currentCodeName, dateData, getHotelData, i18n.language]);

  // Direct Link
  let link = `/detail/${hotelData?.id}/${dateData.current}/${
    dateData.later
  }/standard_guests=1&standard_guest_age_1=18/minor_guests=0/${"fs"}`;
  return (
    <div className="suggest-hotel">
      {hotelData && (
        <>
          <div className="hotel-img">
            <img
              src={
                hotelData.hotel_associated_main_image
                  ? `${process.env.REACT_APP_IMAGE}${hotelData.hotel_associated_main_image.image}`
                  : defaultImg
              }
              alt="hotelImg"
            />
          </div>
          <div className="hotel-about">
            <h1 className="hotel-name">{hotelData?.hotel_name}</h1>
            {hotelData.hotel_suites.length !== 0 && (
              <div className="price-area">
                {hotelData.hotel_suites?.suite_price_base_total !==
                  hotelData.hotel_suites?.suite_price_total && (
                  <span className="prev-price">
                    {hotelData.hotel_suites?.suite_price_base_total}{" "}
                    {currentCodeName}
                  </span>
                )}{" "}
                <span className="current-price">
                  {hotelData.hotel_suites?.suite_price_total}
                  {currentCodeName}
                </span>
              </div>
            )}
            <div
              className="detail editor"
              dangerouslySetInnerHTML={{
                __html:
                  hotelData?.hotel_associated_descriptions[0]?.description_long,
              }}
            />

            <div className="btn-area">
              <Link className="learn-more-btn" to={link}>
                {t("btn.learn-more")}
              </Link>
            </div>
          </div>
        </>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default SuggestHotel;
