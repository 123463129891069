import { useContext } from "react";
import { MainContext } from "../../utils/MainContext";
import close from "../../assets/img/icon/close-circle.svg";
import { useTranslation } from "react-i18next";

const SelectHotelCardMobile = ({ hotel }) => {
  // Global State
  const {
    setSelectHotelVisible,
    setSelectHotelOrRoomName,
    selectHotelOrRoomName,
  } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  return (
    <div
      className={
        selectHotelOrRoomName?.name === hotel.hotel_name
          ? "result-card active"
          : "result-card"
      }
      key={hotel.id}
    >
      <div className="left-side">
        <div className="hotel-img">
          {hotel.hotel_associated_main_image ? (
            <img
              src={`${process.env.REACT_APP_IMAGE}${hotel.hotel_associated_main_image.image}`}
              alt="hotel-img"
            />
          ) : (
            <img src={""} alt="hotel-img" />
          )}
        </div>
        <h2 className="hotel-name">{hotel.hotel_name}</h2>
      </div>
      <div className="right-side">
        {selectHotelOrRoomName?.name === hotel.hotel_name ? (
          <button
            className="close"
            onClick={() => {
              setSelectHotelOrRoomName(
                selectHotelOrRoomName?.name === hotel.hotel_name && {
                  ...selectHotelOrRoomName,
                  id: "",
                  name: "",
                }
              );
              setSelectHotelVisible(false);
            }}
          >
            <img src={close} alt="delete" />
          </button>
        ) : (
          <button
            className="select"
            onClick={() => {
              setSelectHotelOrRoomName(
                selectHotelOrRoomName?.name !== hotel.hotel_name && {
                  ...selectHotelOrRoomName,
                  id: hotel.id,
                  name: hotel.hotel_name,
                }
              );
              setSelectHotelVisible(false);
            }}
          >
            {t("search-rezervasion.choose-hotel")}
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectHotelCardMobile;
