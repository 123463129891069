// React
import { useContext, useState } from "react";

// Context
import { MainContext } from "../utils/MainContext";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Icons
import close from "../assets/img/icon/close-circle.svg";
import send from "../assets/img/icon/send.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
const CommetModal = ({ data, page }) => {
  // Global State
  const { commentVisible, setCommentVisible, setUserCommentData } =
    useContext(MainContext);

  // Location
  const location = useLocation();

  // Local State
  const [starHover, setStarHover] = useState(0);
  const [select, setSelect] = useState(0);
  let { id } = useParams();

  // Language
  const { t, i18n } = useTranslation();

  // Yup schema
  const emailSchema = object({
    user_name: string().required().trim(),
    comment: string().required().trim(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  //   Send Newsletter Post Request
  const sendComment = async (data) => {
    const body = new FormData();
    body.append("review_approval_status", true);
    body.append("review_locale", i18n.language);
    body.append("review_text", data.comment);
    body.append("review_reviewer_name", data.user_name);
    body.append("review_associated_hotel", id);
    body.append("review_star_rating", select === 0 ? 1 : select);
    await axios
      .post(`https://source.naftalan.biz/hotel-user-review/create`, body, {
        crossdomain: true,
      })
      .then((res) => {
        setUserCommentData((prevdata) => [res.data, ...prevdata]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={commentVisible ? "commit-modal active" : "commit-modal "}>
      <div
        className={commentVisible ? "overlay active" : "overlay "}
        onClick={() => setCommentVisible(false)}
      ></div>
      <div className="review" data-aos="fade-up" data-aos-duration="700">
        <div className="head">
          <h4 className="caption">{t("comment-model.caption")}</h4>
          <div className="close" onClick={() => setCommentVisible(false)}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className="body">
          <ul
            className={
              page === "home"
                ? `commit-list home ${page === "detail" ? "hotelDetail" : ""}`
                : `commit-list ${page === "detail" ? "hotelDetail" : ""}`
            }
          >
            {data.map((comment) => (
              <li className="commit" key={comment.id}>
                <div className="personal-area">
                  {page !== "detail" && (
                    <div className="personal-img">
                      <img
                        src={`${process.env.REACT_APP_IMAGE}${comment.review_reviewer_image}`}
                        alt="personalImg"
                      />
                    </div>
                  )}

                  <div className="personal-info">
                    <h4 className="person-name">
                      {comment.review_reviewer_name}
                    </h4>
                    {page !== "detail" ? (
                      <h6 className="job"> {comment.review_reviewer_origin}</h6>
                    ) : (
                      <div className="evaluation">
                        {Array.from({ length: 5 }, (_, index) => (
                          <div
                            key={index}
                            className={
                              comment.review_star_rating >= index + 1
                                ? "icon selectStar"
                                : "icon"
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                stroke="#b0b0b0"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {location.pathname === "/" ? (
                  <div
                    className="text editor"
                    dangerouslySetInnerHTML={{
                      __html: comment.review_text,
                    }}
                  />
                ) : (
                  <p className="text">{comment.review_text}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
        {page !== "home" && (
          <form className="comment-form" onSubmit={handleSubmit(sendComment)}>
            <div className="top-line">
              <div className="form-group">
                <input
                  name="user_name"
                  id="user_name"
                  placeholder={t("comment-model.name")}
                  {...register("user_name")}
                ></input>
              </div>
              <div className="evaluation">
                {Array.from({ length: 5 }, (_, index) => (
                  <div
                    key={index}
                    className={select >= index + 1 ? "icon selectStar" : "icon"}
                    onMouseEnter={() => setStarHover(index + 1)}
                    onMouseLeave={() => setStarHover(0)}
                    onClick={() => setSelect(index + 1)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={starHover >= index + 1 ? "active" : ""}
                        d="M13.7309 3.50965L15.4909 7.02965C15.7309 7.51965 16.3709 7.98965 16.9109 8.07965L20.1009 8.60965C22.1409 8.94965 22.6209 10.4296 21.1509 11.8896L18.6709 14.3696C18.2509 14.7896 18.0209 15.5996 18.1509 16.1796L18.8609 19.2496C19.4209 21.6796 18.1309 22.6196 15.9809 21.3496L12.9909 19.5796C12.4509 19.2596 11.5609 19.2596 11.0109 19.5796L8.02089 21.3496C5.88089 22.6196 4.58089 21.6696 5.14089 19.2496L5.85089 16.1796C5.98089 15.5996 5.75089 14.7896 5.33089 14.3696L2.85089 11.8896C1.39089 10.4296 1.86089 8.94965 3.90089 8.60965L7.09089 8.07965C7.62089 7.98965 8.26089 7.51965 8.50089 7.02965L10.2609 3.50965C11.2209 1.59965 12.7809 1.59965 13.7309 3.50965Z"
                        stroke="#b0b0b0"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="comment"
                id="comment"
                placeholder={t("comment-model.comment-write")}
                {...register("comment")}
              ></textarea>
              <button type="submit">
                <img src={send} alt="send" />
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CommetModal;
