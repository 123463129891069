import { MainContext } from "../../utils/MainContext";

import React, { useCallback, useContext } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useLocation } from "react-router-dom";

const CalendarModal = () => {
  // Global State
  const {
    calendarVisible,
    setCalendarVisible,
    selectDate,
    setSelectDate,
    handleEdit,
    setHandleEdit,
    setDateError,
    roomGroupELement,
  } = useContext(MainContext);

  // Location
  const location = useLocation();

  // Change Date
  const onChange = (nextValue) => {
    let startDate = selectDate.start;
    let endDate = selectDate.end;

    if (startDate === "" || endDate === "") {
      if (handleEdit === "start") {
        setSelectDate({ ...selectDate, start: nextValue });
      } else {
        setSelectDate({ ...selectDate, end: nextValue });
      }
    } else if (handleEdit === "start") {
      if (nextValue > endDate) {
        setSelectDate({ start: endDate, end: nextValue });
      } else {
        setSelectDate({ ...selectDate, start: nextValue });
      }
    } else {
      if (nextValue > startDate) {
        setSelectDate({ ...selectDate, end: nextValue });
      } else {
        setSelectDate({ start: nextValue, end: startDate });
      }
    }
    setDateError({ start: false, end: false });
    setCalendarVisible(false);
    setHandleEdit("");
    checkRoom();
  };

  // Disable Prev Date
  const tileDisabled = ({ date, view }) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0);
  };

  // Check Data
  const checkRoom = useCallback(() => {
    if (location.pathname.split("/")[1] === "detail") {
      const button = roomGroupELement && roomGroupELement.current;

      const timer = setTimeout(() => {
        button.click();
      }, 200);
      return () => clearTimeout(timer);
    } else {
      return false;
    }
  }, [location.pathname, roomGroupELement]);

  return (
    <div
      className={
        calendarVisible ? "desktop-calendar active" : "desktop-calendar"
      }
    >
      <div className="calendar" data-aos="fade-up" data-aos-duration="700">
        <Calendar
          onChange={onChange}
          value={selectDate[handleEdit]}
          tileDisabled={tileDisabled}
        />
      </div>
      ;
      <div
        className="overlay"
        onClick={() => {
          setCalendarVisible(false);
          setHandleEdit("");
        }}
      ></div>
    </div>
  );
};

export default CalendarModal;
