import { useContext, useEffect, useState } from "react";
import rightIcon from "../../assets/img/icon/arrow-right.-white.svg";
import { MainContext } from "../../utils/MainContext";
import defaultImg from "../../assets/img/IMG-not-found.svg";

const HotelImgs = ({ hotelName, rating, mainPhoto, additionPhoto }) => {
  // Global State
  const { setHotelView } = useContext(MainContext);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  // test img loader
  useEffect(() => {
    const loadImage = () => {
      setImagesLoaded(true);
    };

    // Listen for 'load' event on each image
    const images = document.querySelectorAll(".hotel-imgs img");
    images.forEach((img) => {
      img.addEventListener("load", loadImage);
    });

    return () => {
      // Clean up event listeners on unmount
      images.forEach((img) => {
        img.removeEventListener("load", loadImage);
      });
    };
  }, []);

  return (
    <section className="hotel-imgs">
      <div className="container">
        <div className="head">
          <h1 className="hotel-name">{hotelName}</h1>
          <div className="evaluation">
            {Array.from({ length: 5 }, (_, index) => (
              <div
                key={index}
                className={rating >= index + 1 ? "icon selectStar" : "icon"}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                    stroke="#b0b0b0"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="left-side">
            <img
              src={
                mainPhoto
                  ? `${process.env.REACT_APP_IMAGE}${mainPhoto.image}`
                  : defaultImg
              }
              alt="mainPhoto"
              onClick={() => setHotelView(true)}
            />
          </div>
          <div className="right-side">
            <div className="top">
              <div className="img-box" onClick={() => setHotelView(true)}>
                <img
                  src={
                    additionPhoto.length !== 0 && additionPhoto[0]
                      ? `${process.env.REACT_APP_IMAGE}${additionPhoto[0]?.image}`
                      : defaultImg
                  }
                  alt="additionPhoto"
                />
              </div>
              <div className="img-box" onClick={() => setHotelView(true)}>
                <img
                  src={
                    additionPhoto.length !== 0 && additionPhoto[1]
                      ? `${process.env.REACT_APP_IMAGE}${additionPhoto[1]?.image}`
                      : defaultImg
                  }
                  alt="additionPhoto"
                />
              </div>
            </div>
            <div className="bottom">
              <div className="img-box" onClick={() => setHotelView(true)}>
                <img
                  src={
                    additionPhoto.length !== 0 && additionPhoto[2]
                      ? `${process.env.REACT_APP_IMAGE}${additionPhoto[2]?.image}`
                      : defaultImg
                  }
                  alt="additionPhoto"
                />
              </div>
              <div className="img-box" onClick={() => setHotelView(true)}>
                <img
                  src={
                    additionPhoto.length !== 0 && additionPhoto[3]
                      ? `${process.env.REACT_APP_IMAGE}${additionPhoto[3]?.image}`
                      : defaultImg
                  }
                  alt="additionPhoto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="general-hotel-photo">
          {additionPhoto.length !== 0 ? (
            additionPhoto?.map((photo) => (
              <div
                className="general-hotel-img"
                onClick={() => setHotelView(true)}
                key={photo.id}
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE}${photo.image}`}
                  alt=""
                />
              </div>
            ))
          ) : (
            <>
              <div
                className="general-hotel-img"
                onClick={() => setHotelView(true)}
              >
                <img src={defaultImg} alt="" />
              </div>
              <div
                className="general-hotel-img"
                onClick={() => setHotelView(true)}
              >
                <img src={defaultImg} alt="" />
              </div>
                <div
                  className="general-hotel-img"
                  onClick={() => setHotelView(true)}
                >
                  <img src={defaultImg} alt="" />
                </div>
            </>
          )}

          <div className="switch" onClick={() => setHotelView(true)}>
            <img src={rightIcon} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotelImgs;
