// React
import { useCallback, useEffect, useState } from "react";

// React Router Dom
import { useNavigate } from "react-router-dom";

// Axios
import axios from "axios";

// Component
import Loading from "../../component/Loading";
import { useTranslation } from "react-i18next";

// Default IMg
// import defaultImg from "../../assets/img/IMG-not-found.svg";

const SanetoryInfo = () => {
  // Local State
  const [naftalanData, setNaftalanData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Naviagtion
  const navigate = useNavigate();

  // Language
  const { t, i18n } = useTranslation();

  // GEt Request
  const getNaftalanInfo = useCallback(async () => {
    setLoading(true);
    await axios
      .get(process.env.REACT_APP_NAFTALAN_HISTORY, {
        crossdomain: true,
      })
      .then((res) => {
        setNaftalanData(res.data);
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, []);

  // Rendering
  useEffect(() => {
    getNaftalanInfo();
  }, [getNaftalanInfo]);

  return (
    <div className="sanetory-info">
      {naftalanData && (
        <div className="container">
          <div className="row">
            <div className="sanetory-img">
              <img
                src={`${process.env.REACT_APP_IMAGE}${naftalanData.history_image}`}
                // onError={(event) => (event.target.src = defaultImg)}
                // loading="lazy"
                alt="naftalan"
              />
            </div>
            <div className="right-side">
              <div className="sanetory-history">
                <h1 className="caption">{t("header.naftalan-history")}</h1>
                <div
                  className="text editor"
                  dangerouslySetInnerHTML={{
                    __html: naftalanData[`history_text_${i18n.language}`],
                  }}
                />
              </div>
              <button
                className="moreBtn"
                onClick={() => navigate("/naftalan-history")}
              >
                {t("btn.explore-more")}
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </div>
  );
};

export default SanetoryInfo;
