// React
import { useCallback, useContext, useEffect, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Swiper
import "swiper/css";
import "swiper/css/navigation";

// AXios
import axios from "axios";

// Language
import { useTranslation } from "react-i18next";

// Components
import SelectHotelCardMobileLoading from "./SelectHotelCardMobileLoading";
import SelectHotelCardMobile from "./SelectHotelCardMobile";

// Icons
import search from "../../assets/img/icon/search.svg";

const SelectHotel = () => {
  // Gloabl State
  const { selectHotelVisible, setSelectHotelVisible } = useContext(MainContext);

  // Local State
  const [data, setData] = useState([]);
  const [erroeVisible, setErrorVisivle] = useState(false);

  // Language
  const { t, i18n } = useTranslation();

  // Slier

  // GetData
  const getHotelDAta = useCallback(async () => {
    setData(["loading"]);
    await axios
      .get(`${process.env.REACT_APP_HOTEL_READ_ALL}?locale=${i18n.language}`)
      .then((res) => setData(res.data))
      .catch((err) => err && setData([]));
  }, [i18n.language]);

  // Search Hotel
  const searchHotel = useCallback(
    async (searchData) => {
      setData(["loading"]);
      await axios
        .get(
          `${process.env.REACT_APP_HOTEL_READ_BY_ID}/${searchData}?locale=${i18n.language}`
        )
        .then((res) => {
          if (res.data.length === 0) {
            setErrorVisivle(true);
            setData([]);
          } else {
            setData(res.data);
          }
        })
        .catch((err) => console.log(err));
    },
    [i18n.language]
  );

  // Rendering Data
  useEffect(() => {
    if (selectHotelVisible && i18n.language) {
      getHotelDAta();
    }
  }, [getHotelDAta, i18n.language, selectHotelVisible]);

  // Data Controle
  const getSearchDataControle = (e) => {
    setErrorVisivle(false);
    if (e.target.value.length > 2) {
      searchHotel(e.target.value);
    } else {
      getHotelDAta();
    }
  };

  return (
    <div
      className={selectHotelVisible ? "select-hotel active" : "select-hotel"}
    >
      <div className="select-area" data-aos="fade-up" data-aos-duration="700">
        <div className="search-hotel-mobile">
          <form className="seacrh-form">
            <div className="icon">
              <img src={search} alt="seacrh" />
            </div>
            <input
              type="text"
              placeholder="Search hotel"
              autoComplete="off"
              onChange={(e) => getSearchDataControle(e)}
            />
          </form>
          <div className="search-result">
            {erroeVisible ? (
              <div className="not-found-hotel">
                <p className="text">{t("select-hotel.error")}</p>
              </div>
            ) : data[0] === "loading" ? (
              Array.from({ length: 4 }, (_, index) => (
                <SelectHotelCardMobileLoading key={index} />
              ))
            ) : (
              data?.length !== 0 &&
              data[0] !== "loading" &&
              data.map((hotel) => (
                <SelectHotelCardMobile hotel={hotel} key={hotel.id} />
              ))
            )}
          </div>
        </div>
      </div>
      <div
        className={selectHotelVisible ? "overlay active" : "overlay"}
        onClick={() => setSelectHotelVisible(false)}
      ></div>
    </div>
  );
};

export default SelectHotel;
