// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { useLocation, useParams } from "react-router-dom";

//Context
import { MainContext } from "../utils/MainContext";

// Section
import HotelImgs from "./hotel-detail-section/HotelImgs";
import HotelAbout from "./hotel-detail-section/HotelAbout";
import HotelComment from "./hotel-detail-section/HotelComment";
import Rezervasion from "../component/hotelDetail/Rezervasion";
import HotelContactArea from "./hotel-detail-section/HotelContactArea";

// Modal
import HotelAboutModal from "../component/hotelDetail/HotelAboutModal";
import CommetModal from "../component/CommetModal";
import HotelViewModal from "../component/hotelDetail/HotelViewModal";

// Axios
import axios from "axios";

// Language
import { useTranslation } from "react-i18next";

// Helmet
import { Helmet } from "react-helmet";

// Component
import Loading from "../component/Loading";
import SelectRoomBase from "../component/hotelDetail/SelectRoomBase";
import RoomCardLoading from "../component/hotelDetail/RoomCardLoading";
import RoomViewModal from "../component/hotelDetail/RoomViewModal";

const HotelDetail = () => {
  // Gloabl State
  const {
    hotelView,
    hotelAboutVisible,
    commentVisible,
    usercommentData,
    setUserCommentData,
    currentCodeName,
    personalCountVisible,
    setPersonalCountVisible,
    rotelView,

    // Room
    roomData,
    setRoomData,
  } = useContext(MainContext);

  // Params
  let { id, startDate, endDate, adultCount, childrenCount, specialSearch } =
    useParams();

  // Router
  const { pathname } = useLocation();

  // Language
  const { i18n } = useTranslation();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Local State
  const [hotelData, setHotelData] = useState(null);
  const [loading, setLoading] = useState(false);

  // Get Data Schema
  const getOneHotelData = useCallback(async () => {
    setRoomData(["loading"]);

    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_HOTEL_FILTER_ID}/${id}?date_from=${startDate}&date_to=${endDate}&${adultCount}&${childrenCount}&currency_code=${currentCodeName}&locale=${i18n.language}`
      )
      .then((res) => {
        setHotelData(res.data);
        if (res.data.hotel_suites.length !== 0 && specialSearch === "fs") {
          setRoomData(res.data.hotel_suites);
        }
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, [
    adultCount,
    childrenCount,
    currentCodeName,
    endDate,
    i18n.language,
    id,
    setRoomData,
    specialSearch,
    startDate,
  ]);
  // Get Comment Data Schema
  const getComment = useCallback(async () => {
    setLoading(true);
    setUserCommentData(null);
    await axios
      .get(
        `${process.env.REACT_APP_HOTEL_USER_REVIEW_ALL}/${id}?locale=${i18n.language}`,
        { crossdomain: true }
      )
      .then((res) => {
        setUserCommentData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          setUserCommentData(null);
          setLoading(false);
        }
      });
  }, [i18n.language, id, setUserCommentData]);
  // Get Request
  useEffect(() => {
    if (
      startDate &&
      endDate &&
      adultCount &&
      childrenCount &&
      specialSearch &&
      i18n.language &&
      currentCodeName
    ) {
      getOneHotelData();
      getComment();
    }
  }, [
    adultCount,
    childrenCount,
    currentCodeName,
    endDate,
    getComment,
    getOneHotelData,
    i18n.language,
    specialSearch,
    startDate,
  ]);

  return (
    <main>
      {loading && <Loading />}
      {hotelData && hotelData.hotel_associated_seos.length !== 0 && (
        <Helmet>
          <title>{hotelData.hotel_associated_seos[0].seo_title}</title>
          <meta
            name="description"
            content={hotelData.hotel_associated_seos[0].seo_description}
          />
        </Helmet>
      ) }

      {hotelData && (
        <>
          {/* Image Section */}
          <HotelImgs
            hotelName={hotelData.hotel_name}
            rating={hotelData.hotel_star_rating}
            mainPhoto={hotelData?.hotel_associated_main_image}
            additionPhoto={hotelData?.hotel_associated_images}
          />
          {hotelView && (
            <HotelViewModal
              additionPhoto={hotelData?.hotel_associated_images}
            />
          )}

          {/* About Section  */}
          {hotelData.hotel_associated_descriptions.length !== 0 && (
            <HotelAbout description={hotelData.hotel_associated_descriptions} />
          )}

          {hotelAboutVisible && (
            <HotelAboutModal
              description={hotelData.hotel_associated_descriptions}
              hotelName={hotelData.hotel_name}
              atributes={hotelData.hotel_associated_attributes}
            />
          )}
          {/* Rezervasion Section  */}
          <Rezervasion
            id={id}
            startDateSpecific={startDate}
            endDateSpecific={endDate}
            adultCount={adultCount}
            childrenCount={childrenCount}
          />

          {/* Rooms */}
          <div className="container">
            {roomData[0] === "loading"
              ? Array.from({ length: 6 }, (_, index) => (
                  <RoomCardLoading key={index} />
                ))
              : roomData?.length !== 0 &&
                roomData[0] !== "loading" && (
                  <SelectRoomBase rooms={roomData} hotelId={id} />
                )}
          </div>
        </>
      )}

      {rotelView && <RoomViewModal />}

      {/* Comment Section */}
      {usercommentData && <HotelComment data={usercommentData} />}
      {commentVisible && <CommetModal data={usercommentData} page={"detail"} />}

      {/* Contact Area */}
      {hotelData && (
        <HotelContactArea
          adress={hotelData.hotel_address}
          mail={hotelData.hotel_email}
          adressLink={hotelData.hotel_map_url}
          phone={hotelData.hotel_phone}
          faxCode={hotelData.hotel_fax}
        />
      )}
      {personalCountVisible && (
        <div
          className="close-personal-count-overlay"
          onClick={() => setPersonalCountVisible(false)}
        ></div>
      )}
    </main>
  );
};

export default HotelDetail;
