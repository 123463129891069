// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { Link } from "react-router-dom";

// Context
import { MainContext } from "../utils/MainContext";

// Axios
import axios from "axios";

// Language
import { useTranslation } from "react-i18next";

// Local DataBase
import socialIcons from "../db/socialMediaIcons";

const Footer = () => {
  // Global State
  const { menuData } = useContext(MainContext);

  // Local State
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [customPageDAta, setCustomPageData] = useState([]);

  // Language
  const { t, i18n } = useTranslation();

  // Social Media Get  Request
  const getSocialMedial = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_SOCIAL_ICON_ALL_LIST, {
        crossdomain: true,
      })
      .then((res) => {
        setSocialMediaData(res.data);
      })
      .catch((err) => {
        console.log(err);
        // navigate("/error");
      });
  }, []);

  // Custom Page Get   Request
  const getCustomPageData = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_CUSTOM_PAGE_ALL_LIST}?locale=${i18n.language}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCustomPageData(res.data);

        menuData.forEach((menuItem) => {
          const dropdownItems = res.data
            .filter(
              (dataItem) => dataItem.page_associated_section === menuItem.type
            )
            .map((dataItem) => ({
              id: dataItem.id,
              page_associated_section: dataItem.page_associated_section,
              page_name_az: dataItem.page_name_az,
              page_name_en: dataItem.page_name_en,
              page_name_ru: dataItem.page_name_ru,
              url: `/custom-page/${dataItem[`page_name_${i18n.language}`]}/${
                dataItem.id
              }`,
            }));

          if (dropdownItems.length > 0) {
            if (!menuItem.dropdown) {
              menuItem.dropdown = [];
            }
            menuItem.dropdown.push(...dropdownItems);
          }
        });

        return menuData;
      })
      .catch((err) => {
        console.log(err);
        // navigate("/error");
      });
  }, [i18n.language, menuData]);

  // Get Request
  useEffect(() => {
    if (i18n.language) {
      getSocialMedial();
      getCustomPageData();
    }
  }, [getCustomPageData, getSocialMedial, i18n.language]);

  // Open Browser Modal
  const handleOpenWindow = (url) => {
    window.open(
      url,
      "newWin",
      "toolbar=0, top=500, left=500, location=0, directories=0, status=0, menubar=0, scrollbars=0, resizable=0, width=320, height=500"
    );
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="subscribe-area">
          <div className="left-side">
            <h6 className="subscibe-caption">{t("footer.capiton")}</h6>
          </div>
          <Link
            to="https://app.getresponse.com/site2/nigmmdv/?u=Bvuur&webforms_id=MrZK"
            onClick={(e) => {
              e.preventDefault();
              handleOpenWindow(
                "https://app.getresponse.com/site2/nigmmdv/?u=Bvuur&webforms_id=MrZK"
              );
            }}
            className="subs-submit"
          >
            {t("footer.subscribe")}
          </Link>
        </div>

        <div className="nav-and-social-area">
          <ul className="footer-nav-list">
            {customPageDAta.length !== 0 &&
              customPageDAta.map(
                (page) =>
                  page.page_associated_section === "footer" && (
                    <li className="nav-item" key={page.id}>
                      <Link
                        to={`custom-page/${
                          page[`page_name_${i18n.language}`]
                        }/${page.id}`}
                      >
                        {page[`page_name_${i18n.language}`]}
                      </Link>
                    </li>
                  )
              )}
          </ul>
          <ul className="social-list">
            {socialMediaData.map((media) => (
              <li className="social-link" key={media.id}>
                {socialIcons.map((mediaIcon, index) => {
                  const key = `${media.id}-${index}`;
                  return (
                    <Link
                      to={media.social_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="icon"
                      key={key}
                    >
                      {mediaIcon.socialPlatform === media.social_media_path && (
                        <img
                          src={mediaIcon.icon}
                          alt={mediaIcon.socialPlatform}
                        />
                      )}
                    </Link>
                  );
                })}
              </li>
            ))}
          </ul>
        </div>
        <div className="last-area">
          <p className="last-paragraph">© 2023 NaftalanBooking</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
