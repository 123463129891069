// // React
// import { useContext } from "react";

// // Recat Router Dom
// import { NavLink } from "react-router-dom";

// // Context
// import { MainContext } from "../../utils/MainContext";

// // Icon
// import close from "../../assets/img/icon/close.svg";
// import { useTranslation } from "react-i18next";

// const MobileMenu = () => {
//   //   Global State
//   const { mobileMenu, setMobileMenu } = useContext(MainContext);

//   // Translation
//   const { t } = useTranslation();
//   return (
//     <div className={mobileMenu ? "mobile-menu isOpenMenu" : "mobile-menu"}>
//       <div className="head" onClick={() => setMobileMenu(false)}>
//         <img src={close} alt="close" />
//       </div>
//       <div className="body">
//         <ul className="mobile-list">
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.home")}
//               </NavLink>
//             </div>
//           </li>
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/naftalan-history"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.naftalan-history")}
//               </NavLink>
//             </div>
//           </li>
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/sanatoriums"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.sanatoriums")}
//               </NavLink>
//             </div>
//           </li>
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/naftalan-diseas"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.naftalan-diseas")}
//               </NavLink>
//             </div>
//           </li>
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/question-answer"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.question-answer")}
//               </NavLink>
//             </div>
//           </li>
//           <li className="mobile-item">
//             <div className="dropmenu-head">
//               <NavLink
//                 to={"/contact"}
//                 className="directory-switch"
//                 onClick={() => setMobileMenu(false)}
//               >
//                 {t("header.contact")}
//               </NavLink>
//             </div>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default MobileMenu;
// React
import { useContext, useState } from "react";

// Recat Router Dom
import { NavLink } from "react-router-dom";

// Context
import { MainContext } from "../../utils/MainContext";

// Icon
import close from "../../assets/img/icon/close.svg";
import right from "../../assets/img/icon/arrow-right.svg";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  //   Global State
  const { mobileMenu, setMobileMenu, menuData } = useContext(MainContext);

  // Local State
  const [isOpen, setIsopen] = useState(null);

  const { i18n } = useTranslation();

  return (
    <div className={mobileMenu ? "mobile-menu isOpenMenu" : "mobile-menu"}>
      <div className="head" onClick={() => setMobileMenu(false)}>
        <img src={close} alt="close" />
      </div>
      <div className="body">
        <ul className="mobile-list">
          {menuData.map((item) => (
            <li
              className="mobile-item"
              key={item.id}
              onClick={() =>
                item.menu === isOpen ? setIsopen(null) : setIsopen(item.menu)
              }
            >
              {/* Check Mobile Menu */}
              <div className="dropmenu-head">
                {item.dropdown ? (
                  <NavLink className="directory-drop-switch" to={item.url}>
                    {item.menu}
                    {item.dropdown && (
                      <div
                        className={
                          item.menu === isOpen ? "icon active" : "icon"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          item.menu === isOpen
                            ? setIsopen(null)
                            : setIsopen(item.menu);
                        }}
                      >
                        <img src={right} alt="right" />
                      </div>
                    )}
                  </NavLink>
                ) : (
                  <NavLink
                    to={item.url}
                    className="directory-switch"
                    onClick={() => setMobileMenu(false)}
                  >
                    {item.menu}
                  </NavLink>
                )}
              </div>

              {/* Check DropDown */}
              {item.dropdown && (
                <div
                  className={
                    isOpen === item.menu
                      ? "dropmenu-body openDropMenu"
                      : "dropmenu-body "
                  }
                  style={{
                    height:
                      isOpen === item.menu
                        ? item.dropdown.length * 28 +
                          (item.dropdown.length - 1) * 6 +
                          10
                        : 0,
                  }}
                >
                  <ul className="dropmenu-list">
                    {item.dropdown.map((mobileMenu) => (
                      <li className="dropmenu-item" key={mobileMenu.id}>
                        <NavLink
                          to={mobileMenu.url}
                          onClick={() => setMobileMenu(false)}
                          style={{
                            pointerEvents:
                              isOpen === item.menu ? "auto" : "none",
                          }}
                        >
                          {mobileMenu[`page_name_${i18n.language}`]}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
