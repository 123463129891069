// React Router Dom
import { useCallback, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";

// Axios
import axios from "axios";

// React ROuter Dom
import { Link } from "react-router-dom";

// DEfault Img
import defaultImg from "../../assets/img/IMG-not-found.svg";

const HeroSlider = () => {
  // Local State
  const [bannerData, setBannerData] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const getBannerData = useCallback(async () => {
    await axios
      .get(process.env.REACT_APP_PROMOTION_BANNER, {
        crossdomain: true,
      })
      .then((res) => {
        const currentDate = new Date();

        const currentYear = currentDate.getFullYear();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        );
        const currentDay = String(currentDate.getDate()).padStart(2, "0");

        const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

        // Bir gün sonrasını hesapla
        const nextDay = new Date(currentDate);
        nextDay.setDate(nextDay.getDate() + 1);

        const nextDayYear = nextDay.getFullYear();
        const nextDayMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
        const nextDayDay = String(nextDay.getDate()).padStart(2, "0");

        const nextDayDateString = `${nextDayYear}-${nextDayMonth}-${nextDayDay}`;
        // Durumu güncelle
        if (currentDateString && nextDayDateString) {
          let updateData = res.data.map((item) => {
            if (item.banner_url.split("/")[2] === "naftalan.biz") {
              let linkTo = `/detail/${
                item.banner_url.split("/")[3]
              }/${currentDateString}/${nextDayDateString}/standard_guests=1&standard_guest_age_1=18/minor_guests=0/fs`;
              return { ...item, banner_url: linkTo };
            }
            return item;
          });
          setBannerData(updateData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // test img loader
  useEffect(() => {
    const loadImage = () => {
      setImagesLoaded(true);
    };

    // Listen for 'load' event on each image
    const images = document.querySelectorAll(".hero-swpier img");
    images.forEach((img) => {
      img.addEventListener("load", loadImage);
    });

    return () => {
      // Clean up event listeners on unmount
      images.forEach((img) => {
        img.removeEventListener("load", loadImage);
      });
    };
  }, []);

  useEffect(() => {
    if (imagesLoaded) {
      console.log("All images loaded, re-rendering component...");
    }
  }, [imagesLoaded]);

  useEffect(() => {
    getBannerData();
  }, [getBannerData]);

  return (
    <div className="hero-slider">
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={false}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="hero-swpier"
      >
        {bannerData &&
          bannerData.map((banner) => (
            <SwiperSlide key={banner.id}>
              <Link to={banner.banner_url}>
                <img
                  src={`${process.env.REACT_APP_IMAGE}${banner.banner_image}`}
                  onError={(event) => (event.target.src = defaultImg)}
                  alt={banner.banner_name}
                  loading="lazy"
                />
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
