import { useContext, useState } from "react";
import { MainContext } from "../../utils/MainContext";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// Icon
import down from "../../assets/img/icon/arrow-down.svg";

// Language
import { useTranslation } from "react-i18next";

import defaultImg from "../../assets/img/IMG-not-found.svg";
import { useNavigate } from "react-router-dom";

const SelectRoomBase = ({ rooms, hotelId }) => {
  // Global State
  const { currentCodeName } = useContext(MainContext);

  const { t, i18n } = useTranslation();
  // Global State
  const {
    setSelectRoomData,
    roomDetailVisible,
    setRoomError,
    personalCountdata,
    setpersonalCountError,
    personalCountError,
    startDate,
    endDate,
    setDateError,
    dateError,
    setStandardGuestAge,
    setMinorGuest,
    setRotelView,
    setRoomImg,
    selectInfo,
  } = useContext(MainContext);

  // Navigate
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(null);

  // const renderedCategories = new Set();

  const check = (selectRoomID) => {
    let dataCheck = true;

    // Check Personal ADult COunt
    if (personalCountdata[0].count === 0) {
      setpersonalCountError({ ...personalCountError, adult: true });
      return false;
    }

    // Check Personal Children Age
    if (personalCountdata[1]?.age.length !== 0) {
      const hasEmptyActive = personalCountdata[1].age.some((item) => {
        if (item.active === "") {
          setpersonalCountError({ ...personalCountError, children: true });
          return true;
        }
        return false;
      });

      if (hasEmptyActive) return false;
    }

    // Check Date
    const formattedStartDate = startDate.split("-").reverse().join("-");
    const formattedEndDate = endDate.split("-").reverse().join("-");
    if (startDate === "") {
      setDateError({ ...dateError, start: true });
      return false;
    }
    if (endDate === "") {
      setDateError({ ...dateError, end: true });
      return false;
    }
    if (startDate === endDate) {
      setDateError({ start: true, end: true });
      return false;
    }

    // if (selectRoomData.id === "") {
    //   setRoomError(true);
    //   return false;
    // }

    if (dataCheck) {
      let standartLineResult = "";
      for (let i = 1; i <= personalCountdata[0].count; i++) {
        standartLineResult += `standard_guest_age_${i}=18`;
        if (i !== personalCountdata[0].count) {
          standartLineResult += "&";
        }
      }
      setStandardGuestAge(standartLineResult);

      const minorGuest = personalCountdata[1]?.age
        .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
        .join("&");
      setMinorGuest(minorGuest);

      let link = `/invoice/${hotelId}/${selectRoomID}/${formattedStartDate}/${formattedEndDate}/standard_guests=${
        personalCountdata[0].count
      }&${standartLineResult}/minor_guests=${personalCountdata[1].count}&${
        minorGuest ? minorGuest + "&" : ""
      }`;
      navigate(link);
    }
  };

  return (
    <>
      {rooms.map((room) => (
        <div
          key={room.id}
          className={
            roomDetailVisible === room.id + "t"
              ? "room-group isVisible"
              : "room-group"
          }
          id={room.id + "t"}
        >
          <div className="head">
            <div className="left-side">
              <h2 className="room-name">
                {room.suite_associated_descriptions.length !== 0 &&
                  room.suite_associated_descriptions[0].description_suite_name}
              </h2>
              <p className="room-about">
                {room.suite_associated_descriptions.length !== 0 &&
                  room.suite_associated_descriptions[0]?.description_short}
              </p>
            </div>
            <div className="right-side">
              <p className="personalcount-and-night-info">
                {t("reservasion.total-price")}
              </p>
              <div className="price-area">
                {room.suite_price_base_total !== room.suite_price_total && (
                  <span className="prev-price">
                    {room.suite_price_base_total} {currentCodeName}
                  </span>
                )}

                <p className="current-price">
                  {room.suite_price_total} {currentCodeName}
                </p>
              </div>
              <p className="personalcount-and-night-info">
                {Number(selectInfo.adult) !== 0 && selectInfo.adult}{" "}
                {Number(selectInfo.adult) === 0
                  ? ""
                  : Number(selectInfo.adult) > 1
                  ? t("search-rezervasion.adults")
                  : t("search-rezervasion.adult")}{" "}
                {Number(selectInfo.children) !== 0 && selectInfo.children}{" "}
                {Number(selectInfo.children) === 0
                  ? ""
                  : Number(selectInfo.children) > 1
                  ? t("search-rezervasion.children")
                  : t("search-rezervasion.child")}{" "}
                {Number(selectInfo.night)}{" "}
                {Number(selectInfo.night) > 4
                  ? t("room.nightss")
                  : Number(selectInfo.night) > 1 && Number(selectInfo.night) < 5
                  ? t("room.nights")
                  : t("room.night")}
              </p>
            </div>
          </div>
          <div className="body">
            <div className="room-detail">
              <div className="discount-and-reservasoin-area">
                <div className="discount-area">
                  {room.suite_applied_special_price_data.discounted !== 0 && (
                    <span className="discount-price special">
                      {
                        room.suite_applied_special_price_data.special_price[
                          `offer_public_name_${i18n.language}`
                        ]
                      }
                    </span>
                  )}

                  {room.suite_applied_discounts_free_night.discounts[0] &&
                    room.suite_applied_discounts_free_night.discounts.map(
                      (discount, index) => (
                        <span
                          className="discount-price"
                          key={index + Math.random().toString(36).substring(7)}
                        >
                          {discount[`discount_public_name_${i18n.language}`]}
                        </span>
                      )
                    )}
                  {room.suite_applied_discounts_percentage.discounts[0] &&
                    room.suite_applied_discounts_percentage.discounts.map(
                      (discount, index) => (
                        <span
                          className="discount-price"
                          key={index + Math.random().toString(36).substring(7)}
                        >
                          {discount[`discount_public_name_${i18n.language}`]}
                        </span>
                      )
                    )}
                </div>

                <button
                  className="select-btn"
                  onClick={() => {
                    check(room.id);
                    setSelectRoomData({
                      id: room.id,
                      name:
                        room.suite_associated_descriptions.length !== 0
                          ? room.suite_associated_descriptions[0]
                              .description_suite_name
                          : room.suite_type,
                      total: room.suite_price_total,
                    });
                    setRoomError(false);
                  }}
                >
                  {t("room.select")}
                </button>
              </div>

              <div
                className="about editor"
                dangerouslySetInnerHTML={{
                  __html:
                    room.suite_associated_descriptions.length !== 0 &&
                    room.suite_associated_descriptions[0]?.description_long,
                }}
              />
              <div className="general-info-area">
                <div className="left-side">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation={true}
                    loop={true}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="room-slider"
                  >
                    {room.suite_associated_images.length !== 0 ? (
                      room.suite_associated_images.map((photo) => (
                        <SwiperSlide
                          key={photo.id}
                          onClick={() => {
                            setRotelView(true);
                            setRoomImg(room.suite_associated_images);
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE}${photo.image}`}
                            alt=""
                          />
                        </SwiperSlide>
                      ))
                    ) : (
                      <SwiperSlide
                        onClick={() => {
                          setRotelView(true);
                          setRoomImg([]);
                        }}
                      >
                        <img src={defaultImg} alt="defaultImg" />
                      </SwiperSlide>
                    )}
                  </Swiper>
                </div>
                <div className="right-side">
                  {room.suite_associated_attributes && (
                    <>
                      {room.suite_associated_attributes.length !== 0 && (
                        <h4 className="characteristics-caption">
                          {t("room.characters")}:
                        </h4>
                      )}
                      <div className="acordion-area">
                        {Object.values(
                          room.suite_associated_attributes.reduce(
                            (acc, item) => {
                              const group = item.attribute_group_id;

                              if (!acc[group]) {
                                acc[group] = [];
                              }

                              acc[group].push(item);

                              return acc;
                            },
                            {}
                          )
                        ).map((item, index) => (
                          <div className="acordion-group" key={index + "t"}>
                            <div
                              className="acordion-head"
                              onClick={() =>
                                setIsOpen(
                                  isOpen === item[0].attribute_group_id
                                    ? null
                                    : item[0].attribute_group_id
                                )
                              }
                              id={item[0].attribute_group_id}
                            >
                              <h5 className="acordion-caption">
                                {
                                  item[0][
                                    `attribute_group_name_${i18n.language}`
                                  ]
                                }
                              </h5>
                              <div
                                className={
                                  isOpen === item[0].attribute_group_id
                                    ? "down-icon"
                                    : "down-icon rotate"
                                }
                              >
                                <img src={down} alt="icon" />
                              </div>
                            </div>
                            <div
                              className={
                                isOpen === item[0].attribute_group_id
                                  ? "acordion-detail openAcordion"
                                  : "acordion-detail"
                              }
                              style={{
                                height:
                                  isOpen === item[0].attribute_group_id
                                    ? "auto"
                                    : 0,
                              }}
                            >
                              <ul className="list">
                                {item.map((atrb) => (
                                  <li className="item" key={atrb.id}>
                                    {atrb[`attribute_name_${i18n.language}`]}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))}

                        {/* <div className="acordion-group">
                          <div
                            className="acordion-head"
                            // onClick={() =>
                            //   setIsOpen(
                            //     isOpen === category ? null : category
                            //   )
                            // }
                            // id={category}
                          >
                            <h5 className="acordion-caption">test</h5>
                            <div
                              className="down-icon"

                              // className={
                              //   isOpen === category
                              //     ? "down-icon"
                              //     : "down-icon rotate"
                              // }
                            >
                              <img src={down} alt="icon" />
                            </div>
                          </div>
                          <div
                            className="acordion-detail openAcordion"
                            // className={
                            //   isOpen === category
                            //     ? "acordion-detail openAcordion"
                            //     : "acordion-detail"
                            // }
                            style={{
                              height: isOpen !== "" ? "auto" : 0,
                            }}
                          >
                            <ul className="list">
                              <li className="item">test</li>
                              <li className="item">test</li>
                              <li className="item">test</li>
                              <li className="item">test</li>
                            </ul>
                          </div>
                        </div> */}
                      </div>
                    </>
                  )}

                  {room.suite_associated_descriptions[0]
                    ?.description_suite_note && (
                    <>
                      <h4 className="notes-caption">{t("room.notes")}:</h4>
                      <div
                        className="note-detail editor"
                        dangerouslySetInnerHTML={{
                          __html:
                            room.suite_associated_descriptions[0]
                              ?.description_suite_note,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SelectRoomBase;
