import { useContext } from "react";
import HeroSlider from "../../component/home/HeroSlider";
import { MainContext } from "../../utils/MainContext";
import SuggestHotel from "../../component/home/SuggestHotel";
import { useTranslation } from "react-i18next";

const Hero = () => {
  // GLobal State
  const { mobileType } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="container">
      {mobileType && <h2 className="caption">{t("hero.reklam")}</h2>}
        
        <div className="row">
          <HeroSlider />
          {!mobileType && <SuggestHotel />}
        </div>
      </div>
    </section>
  );
};

export default Hero;
