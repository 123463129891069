// React
import { useCallback, useEffect, useRef, useState } from "react";

// Language
import { useTranslation } from "react-i18next";

// React Router Dom
import { useLocation } from "react-router-dom";

// Axios
import axios from "axios";

// Component
import Loading from "../component/Loading";

// Icon
import arrowRight from "../assets/img/icon/arrow-right-brown.svg";
import { Helmet } from "react-helmet";

const Information = () => {
  // Language
  const { i18n, t } = useTranslation();

  // Local state
  const [height, setHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [seoData, setSeoData] = useState(null);

  // Element Dom Container
  const acordionElements = useRef([]);

  // Element Push Container
  const addToRefs = (element) => {
    if (element && !acordionElements.current.includes(element)) {
      acordionElements.current.push(element);
    }
  };

  // Set Acordion Visible
  const handleClick = (id) => {
    if (isOpen === id) {
      setIsOpen(null);
      setHeight(null);
    } else {
      setIsOpen(id);
      acordionElements.current.map((item) => {
        if (item.firstChild.id === id) {
          setHeight(item.lastChild.firstChild.offsetHeight);
        }
        return null;
      });
    }
  };

  // Router
  const { pathname } = useLocation();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Faq Get Request
  const getFaqData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_FAQ}?locale=${i18n.language}`, {
        crossdomain: true,
      })
      .then((res) => {
        setFaqData(res.data);
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, [i18n.language]);

  // Rendering Data
  useEffect(() => {
    if (i18n.language) {
      getFaqData();
    }
  }, [getFaqData, i18n.language]);

  const SeoRequest = useCallback(async () => {
    await axios
      .get(`https://source.naftalan.biz/page-seo/read/by-pagename-string/faq`, {
        crossdomain: true,
      })
      .then((res) => {
        // setCommentData(res.data);
        console.log(res.data);
        setSeoData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    SeoRequest();
  }, [SeoRequest]);

  return (
    <>
      <Helmet>
        <title>{seoData && seoData[`seo_title_${i18n.language}`]}</title>
        <meta
          name="description"
          content={seoData && seoData[`seo_description_${i18n.language}`]}
        />
      </Helmet>

      <main>
        <section className="question-answer">
          <div className="head">
            <div className="line">
              <div className="container">
                <h3 className="caption">{t("question-answer.caption")}</h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {faqData.map((faq) => (
                <div
                  className={
                    isOpen === faq.id + "t"
                      ? "question-answ-group active"
                      : "question-answ-group"
                  }
                  ref={addToRefs}
                  key={faq.id}
                >
                  <div
                    className="question"
                    id={faq.id + "t"}
                    onClick={() => handleClick(faq.id + "t")}
                  >
                    <h6 className="title">
                      {faq[`question_title_${i18n.language}`]}
                    </h6>
                    <div className="icon">
                      <img src={arrowRight} alt="arrowRight" />
                    </div>
                  </div>
                  <div
                    className="answer"
                    style={{
                      height: isOpen === faq.id + "t" ? height : 0,
                    }}
                  >
                    <p className="detail">
                      {faq[`question_answer_${i18n.language}`]}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        {loading && <Loading />}
      </main>
    </>
  );
};

export default Information;
