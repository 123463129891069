// React
import { useCallback, useContext, useEffect, useState } from "react";

// Axios
import axios from "axios";

// Language
import { useTranslation } from "react-i18next";

// Components
import Loading from "../component/Loading";

// Params
import { useParams } from "react-router-dom";

// Components

// Context
import { MainContext } from "../utils/MainContext";
import HotelCardPrimary from "../component/home/HotelCardPrimary";
import { Helmet } from "react-helmet";

const CustomPage = () => {
  // Global state
  const { currentCodeName } = useContext(MainContext);

  // Language
  const { t, i18n } = useTranslation();

  //   Params
  const { pageId } = useParams();

  //   Local State
  const [customPageData, setCustomPageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [renderData, setRenderData] = useState("");
  const [dateData, setDateData] = useState({ current: "", later: "" });
  const [hotelData, setHotelData] = useState(null);
  console.log(customPageData);
  // Get hotel Request
  const getHotelData = useCallback(
    async (pageId) => {
      setLoading(true);
      await axios
        .get(
          `https://source.naftalan.biz/hotel/filter/all/by-hotel/${pageId}?date_from=${
            dateData.current
          }&date_to=${
            dateData.later
          }&standard_guests=${1}&standard_guest_age_1=${18}&minor_guests=${0}&currency_code=${currentCodeName}&locale=${
            i18n.language
          }&page_length=${20}&page_number=${1}`,
          { crossdomain: true }
        )
        .then((res) => {
          setHotelData(res.data.page_data);
          setLoading(false);
        })
        .catch(
          (err) => err && setLoading(false)
          // navigate("/error");
        );
    },
    [currentCodeName, dateData, i18n.language]
  );

  // Get Request Data
  const getCustomPageData = useCallback(async () => {
    setLoading(true);
    setRenderData("");
    await axios
      .get(
        `${process.env.REACT_APP_CUSTOM_PAGE_BY_ID}${pageId}?locale=${i18n.language}`
      )
      .then((res) => {
        setCustomPageData(res.data);
        setLoading(false);
        res.data.page_associated_descriptions.map(
          (page) =>
            page.description_locale === i18n.language &&
            setRenderData(page.description_text)
        );
        if (res.data.page_associated_hotels.length !== 0) {
          getHotelData(res.data.page_associated_hotels.join(", "));
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [getHotelData, i18n.language, pageId]);

  //   Rendering Data
  useEffect(() => {
    if (i18n.language) {
      getCustomPageData();
    }
  }, [getCustomPageData, i18n.language]);

  useEffect(() => {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    // Bir gün sonrasını hesapla
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate() + 1);

    const nextDayYear = nextDay.getFullYear();
    const nextDayMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
    const nextDayDay = String(nextDay.getDate()).padStart(2, "0");

    const nextDayDateString = `${nextDayYear}-${nextDayMonth}-${nextDayDay}`;

    // Durumu güncelle
    setDateData({ current: currentDateString, later: nextDayDateString });
  }, []);
  return (
    <>
      {customPageData && customPageData.page_associated_seos.length !== 0 && (
        <Helmet>
          <title>{customPageData.page_associated_seos[0].seo_title}</title>
          <meta
            name="description"
            content={customPageData.page_associated_seos[0].seo_description}
          />
        </Helmet>
      )}
      <div className="naftalan-disease custom-page">
        <div className="container">
          <div
            className="custom-body editor"
            dangerouslySetInnerHTML={{
              __html: renderData,
            }}
          />
        </div>
        {loading && <Loading />}
        {hotelData && (
          <div className="hotels-cards-review">
            <div className="container">
              <h3 className="caption">{t("general.hotel")}</h3>
              <div className="row">
                {hotelData.map((item) => (
                  <HotelCardPrimary
                    hotelData={item}
                    date={dateData}
                    adultCount={`standard_guests=${1}&standard_guest_age_1=${18}`}
                    key={item.id}
                    childrenCount={`minor_guests=${0}`}
                    specialSearch={"fs"}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomPage;
