import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../utils/MainContext";
import close from "../../assets/img/icon/close-circle.svg";
import down from "../../assets/img/icon/arrow-down.svg";
import { useTranslation } from "react-i18next";

const HotelAboutModal = ({ description, hotelName, atributes }) => {
  // Global State
  const { hotelAboutVisible, setHotelAboutVisible } = useContext(MainContext);

  // Local State
  const [updateCategory, setUpdateCategory] = useState([]);

  // Local State
  const [isOpen, setIsopen] = useState(null);
  const renderedCategories = new Set();
  // Language
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (atributes) {
      if (atributes.length !== 0) {
        // setData(carsDb);
        const categories = [
          ...new Set(
            atributes.map((attribute) => attribute.attribute_group_id)
          ),
        ];
        setUpdateCategory(categories);
        setIsopen(categories[0]);
      } else {
        setUpdateCategory([]);
      }
    }
  }, [atributes]);

  return (
    <div
      className={
        hotelAboutVisible ? "hotel-about-modal active" : "hotel-about-modal"
      }
    >
      <div
        className={hotelAboutVisible ? "overlay active" : "overlay "}
        onClick={() => setHotelAboutVisible(false)}
      ></div>
      <div className="review" data-aos="fade-up" data-aos-duration="700">
        <div className="head">
          <h4 className="caption">
            {t("hotel-about-modal.hotel-information")}
          </h4>
          <div className="close" onClick={() => setHotelAboutVisible(false)}>
            <img src={close} alt="close" />
          </div>
        </div>
        <div className="body">
          <h6 className="detail-caption">{hotelName}</h6>
          <div
            className="detail editor"
            dangerouslySetInnerHTML={{
              __html: description[0].description_long,
            }}
          />
          <div className="acordion-area">
            {updateCategory.length !== 0 &&
              updateCategory.map((category) => (
                <div className="acordion-group" key={category}>
                  <div
                    className="acordion-head"
                    onClick={() =>
                      isOpen === category
                        ? setIsopen(null)
                        : setIsopen(category)
                    }
                    id={category}
                  >
                    {atributes.map((attribute) => {
                      if (
                        attribute.attribute_group_id === category &&
                        !renderedCategories.has(
                          attribute[`attribute_group_name_${i18n.language}`]
                        )
                      ) {
                        renderedCategories.add(
                          attribute[`attribute_group_name_${i18n.language}`]
                        );
                        return (
                          <h5 className="acordion-caption" key={attribute.id}>
                            {attribute[`attribute_group_name_${i18n.language}`]}
                          </h5>
                        );
                      }
                      return null;
                    })}

                    <div
                      className={
                        isOpen === category ? "down-icon " : "down-icon rotate"
                      }
                    >
                      <img src={down} alt="icon" />
                    </div>
                  </div>
                  <div
                    className={
                      isOpen === category
                        ? "acordion-detail openAcordion"
                        : "acordion-detail"
                    }
                    style={{
                      height:
                        isOpen === category
                          ? atributes.filter(
                              (attribute) =>
                                attribute.attribute_group_id === category
                            ).length * 20
                          : 0,
                    }}
                  >
                    <ul className="list">
                      {atributes.map(
                        (attribute) =>
                          attribute.attribute_group_id === category && (
                            <li className="item" key={attribute.id}>
                              {attribute[`attribute_name_${i18n.language}`]}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelAboutModal;
