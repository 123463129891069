import React, { useCallback, useContext, useState } from "react";
import { MainContext } from "../../utils/MainContext";

import dropIcon from "../../assets/img/icon/arrow-down.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const PersonalCountBase = () => {
  // Shema
  // const [data, setData] = useState([
  //   {
  //     id: 1,
  //     caption: "Adults",
  //     detail: "Ages 18 or above",
  //     count: 0,
  //   },
  //   {
  //     id: 2,
  //     caption: "Children",
  //     detail: "Ages 0–17",
  //     count: 0,
  //   },
  // ]);

  // Language
  const { i18n, t } = useTranslation();

  // Global State
  const {
    personalCountdata,
    setPersonalCountData,
    personalCountError,
    setpersonalCountError,
    roomGroupELement,
  } = useContext(MainContext);
  const [selectVisible, setSelectVisible] = useState(null);

  // Location
  const location = useLocation();

  // Add Children Age For Object
  const handleUpdateData = () => {
    setPersonalCountData((prevData) => {
      return prevData.map((item) => {
        if (item.caption_en === "Children") {
          let updatedAge = item.age || [];
          while (updatedAge.length < item.count) {
            const newAgeObject = {
              id: Math.random().toString(36).substring(7),
              active: "",
            };
            updatedAge.push(newAgeObject);
          }

          while (updatedAge.length > item.count) {
            updatedAge.pop();
          }
          return { ...item, age: updatedAge };
        } else {
          return item;
        }
      });
    });
    // checkRoom();
  };
  const setCount = (caption, index) => {
    if (personalCountError.adult === true) {
      setpersonalCountError({ ...personalCountError, adult: false });
    }
    const updatedData = personalCountdata.map((item) => {
      if (item[`caption_${i18n.language}`] === caption) {
        return { ...item, count: index };
      } else {
        return item;
      }
    });
    setPersonalCountData(updatedData);
    handleUpdateData();
    checkRoom();
  };

  // Select Children Age
  const activeAge = (ageId, number) => {
    setPersonalCountData((prevData) => {
      return prevData.map((item) => {
        if (item.caption_en === "Children") {
          const updatedAge = (item.age || []).map((ageItem) => {
            if (ageItem.id === ageId) {
              return { ...ageItem, active: number };
            } else {
              return ageItem;
            }
          });

          return { ...item, age: updatedAge };
        } else {
          return item;
        }
      });
    });
    checkRoom();
  };

  // Check Data
  const checkRoom = useCallback(() => {
    if (location.pathname.split("/")[1] === "detail") {
      const button = roomGroupELement && roomGroupELement.current;

      const timer = setTimeout(() => {
        button.click();
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [location.pathname, roomGroupELement]);

  return (
    <div className="person-count-info ">
      {personalCountdata.map((item) => (
        <div className="specific-info" key={item.id}>
          <div className="info">
            <h5 className="caption">{item[`caption_${i18n.language}`]}</h5>
            <p className="detail">{item[`detail${i18n.language}`]}</p>
          </div>
          <div className="count-area">
            <div
              className={
                personalCountError.children && item.active === ""
                  ? "age-info error"
                  : "age-info"
              }
            >
              <div
                className="age-area"
                onClick={() =>
                  setSelectVisible(item.id === selectVisible ? null : item.id)
                }
              >
                <h2 className="number">{item.count}</h2>
                <div className="icon">
                  <img src={dropIcon} alt="drop" />
                </div>
              </div>
              <ul
                className={
                  selectVisible === item.id ? "select selectVisible" : "select"
                }
              >
                {Array.from({ length: 99 }, (_, index) => (
                  <li
                    key={index + 1}
                    className={
                      index === item.active
                        ? "select-item active"
                        : "select-item "
                    }
                    onClick={() => {
                      activeAge(item.id, index);
                      setSelectVisible(null);
                      setCount(item[`caption_${i18n.language}`], index);
                    }}
                  >
                    {index}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
      {personalCountdata[1].age && personalCountdata[1].age.length !== 0 && (
        <div className="select-age">
          {personalCountdata[1].age.map((item) => (
            <div
              className={
                personalCountError.children && item.active === ""
                  ? "age-info error"
                  : "age-info"
              }
              key={item.id}
            >
              <div
                className="age-area"
                onClick={() =>
                  setSelectVisible(item.id === selectVisible ? null : item.id)
                }
              >
                <h2 className="number">
                  {" "}
                  {item.active !== ""
                    ? `${item.active} ${t("personalCount.years-old")}`
                    : t("personalCount.years-old")}{" "}
                </h2>
                <div className="icon">
                  <img src={dropIcon} alt="drop" />
                </div>
              </div>

              <ul
                className={
                  selectVisible === item.id ? "select selectVisible" : "select"
                }
              >
                {Array.from({ length: 18 }, (_, index) => (
                  <li
                    key={index + 1}
                    className={
                      index === item.active
                        ? "select-item active"
                        : "select-item "
                    }
                    onClick={() => {
                      activeAge(item.id, index);
                      setSelectVisible(null);
                      setpersonalCountError({
                        ...personalCountError,
                        children: false,
                      });
                    }}
                  >
                    {index}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div
            className={
              selectVisible ? "select-overlay overlayActive" : "select-overlay "
            }
            onClick={() => setSelectVisible(null)}
          ></div>
        </div>
      )}
    </div>
  );
};

export default PersonalCountBase;
