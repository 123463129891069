import HotelCardSlider from "./HotelCardSlider";

import { Link } from "react-router-dom";
import { useContext } from "react";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const HotelCard = ({
  hotelData,
  date,
  adultCount,
  childrenCount,
  selectInfo,
}) => {
  // Global State
  const { currentCodeName } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  let link = `/detail/${hotelData.id}/${date.current}/${
    date.later
  }/${adultCount}/${childrenCount}/${"fs"}`;

  console.log(hotelData);
  return (
    <div className="hotel-card">
      <div className="hotel-imgs" onClick={(e) => e.stopPropagation()}>
        <HotelCardSlider
          hotelImg={hotelData.hotel_associated_images}
          link={link}
          discount={hotelData?.hotel_suites}
        />
      </div>
      <div to={link} className="hotel-about">
        <div className="about-head">
          <Link to={link} className="hotel-title">
            {hotelData?.hotel_name}
          </Link>
          {hotelData.hotel_star_rating && (
            <div className="evaluation">
              {Array.from({ length: 5 }, (_, index) => (
                <div
                  key={index}
                  className={
                    hotelData.hotel_star_rating >= index + 1
                      ? "icon selectStar"
                      : "icon"
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                      stroke="#b0b0b0"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ))}
            </div>
          )}
        </div>
        <p className="location">{hotelData.hotel_address}</p>
        <div
          className="detail editor"
          dangerouslySetInnerHTML={{
            __html:
              hotelData.hotel_associated_descriptions[0]?.description_long,
          }}
        />
      </div>
      <div className="select-info">
        <p className="personalcount-and-night-info">
          {t("reservasion.total-price")}
        </p>
        {hotelData?.hotel_suites && hotelData?.hotel_suites.length !== 0 && (
          <div className="price-range">
            {hotelData.hotel_suites.suite_price_base_total !==
              hotelData.hotel_suites.suite_price_total && (
              <span className="prev-price">
                {hotelData.hotel_suites.suite_price_base_total}{" "}
                {currentCodeName}
              </span>
            )}

            <span className="max-price">
              {hotelData?.hotel_suites.suite_price_total} {currentCodeName}{" "}
            </span>
          </div>
        )}
        <p className="personalcount-and-night-info">
          {Number(selectInfo.adult) !== 0 && selectInfo.adult}{" "}
          {Number(selectInfo.adult) === 0
            ? ""
            : Number(selectInfo.adult) > 1
            ? t("search-rezervasion.adults")
            : t("search-rezervasion.adult")}{" "}
          {Number(selectInfo.children) !== 0 && selectInfo.children}{" "}
          {Number(selectInfo.children) === 0
            ? ""
            : Number(selectInfo.children) > 1
            ? t("search-rezervasion.children")
            : t("search-rezervasion.child")}{" "}
          {Number(selectInfo.night)}{" "}
          {Number(selectInfo.night) > 4
            ? t("room.nightss")
            : Number(selectInfo.night) > 1 && Number(selectInfo.night) < 5
            ? t("room.nights")
            : t("room.night")}
        </p>
        <Link to={link} className="select-btn">
          {t("select-hotel.select")}
        </Link>
      </div>
    </div>
  );
};

export default HotelCard;
