import { useContext } from "react";
import HotelCommentCard from "../../component/hotelDetail/HotelCommentCard";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";
const HotelComment = ({ data }) => {
  // GLobal State
  const { setCommentVisible } = useContext(MainContext);

  // Language
  const { t } = useTranslation();
  return (
    <section className="hotel-commment-area">
      <div className="container">
        <div className="row">
          {data.slice(0, 4).map((comment, index) => (
            <HotelCommentCard key={index} comment={comment} />
          ))}
        </div>
        <button className="more-btn" onClick={() => setCommentVisible(true)}>
          {data.length > 4
            ? `${t("hotel-comment.show-all")} ${data.length} ${t(
                "hotel-comment.reviews"
              )}`
            : t("hotel-comment.add-comment")}
        </button>
      </div>
    </section>
  );
};

export default HotelComment;
