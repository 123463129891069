import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import HotelCardSlider from "./HotelCardSlider";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const HotelCardPrimary = ({
  hotelData,
  date,
  adultCount,
  childrenCount,
  specialSearch,
}) => {
  // Global State
  const { currentCodeName } = useContext(MainContext);
  // Navigate
  const navigate = useNavigate();

  // Language
  const { t } = useTranslation();

  let link = `/detail/${hotelData.id}/${date.current}/${date.later}/${adultCount}/${childrenCount}/${specialSearch}`;
  return (
    <div className="hotel-card-primary" onClick={() => navigate(link)}>
      <div className="top" onClick={(e) => e.stopPropagation()}>
        <HotelCardSlider
          hotelImg={hotelData.hotel_associated_images}
          link={link}
          discount={hotelData.hotel_suites}
        />
      </div>
      <div className="bottom">
        <div className="head">
          <h3 className="hotel-name">{hotelData?.hotel_name}</h3>

          {hotelData.hotel_star_rating && (
            <div className="evaluation">
              {Array.from({ length: 5 }, (_, index) => (
                <div
                  key={index}
                  className={
                    hotelData.hotel_star_rating >= index + 1
                      ? "icon selectStar"
                      : "icon"
                  }
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                      stroke="#b0b0b0"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              ))}
            </div>
          )}
        </div>

        {hotelData?.hotel_suites && hotelData?.hotel_suites.length !== 0 && (
          <div className="price-range">
            {hotelData.hotel_suites.suite_price_base_total !==
              hotelData.hotel_suites.suite_price_total && (
              <span className="prev-price">
                {hotelData.hotel_suites.suite_price_base_total}{" "}
                {currentCodeName}
              </span>
            )}

            <p className="max-price">
              <span className="text">{t("general.price-prev")}</span>{" "}
              {hotelData?.hotel_suites.suite_price_total} {currentCodeName}{" "}
              <span className="text">{t("general.price-next")}</span>
            </p>
          </div>
        )}

        {hotelData?.hotel_suites && (
          <div className="type">
            <p className="text">
              {" "}
              {hotelData?.hotel_suites.suite_pricing_type ===
              "per-person per-night"
                ? t("search-rezervasion.per-person-per-night")
                : t("search-rezervasion.per-person")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelCardPrimary;
