const MobileCardHotelLoading = () => {
  return (
    <div className="mobile-card-hotel-loading">
      <div className="top" >
      </div>
      <div className="bottom">
        <div className="hotel-name"></div>
        <div className="price-range">
          <span className="prev-price"></span>
          <span className="current-price"></span>
          <span className="text"></span>
        </div>
      </div>
    </div>
  );
};

export default MobileCardHotelLoading;
