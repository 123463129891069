import React from "react";

const HotelCardLoading = () => {
  return (
    <div className="hotel-card-loading">
      <div className="hotel-imgs">
        <div className="img"></div>
      </div>
      <div className="hotel-about">
        <div className="hotel-title"></div>
        <div className="detail"></div>
        <div className="price-area-arrow">
          <div className="price"></div>
        </div>
      </div>
    </div>
  );
};

export default HotelCardLoading;
