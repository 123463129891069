// React
import { useContext, useRef } from "react";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

// Icon
import prev from "../../assets/img/icon/arrow-left-white.svg";
import next from "../../assets/img/icon/arrow-right.-white.svg";
import arrowRight from "../../assets/img/icon/link-arrow-right.svg";

import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
const HomeComment = ({ data }) => {
  /* ------------------------- Dom Manupation With REf ------------------------ */
  const commentSlider = useRef(null);

  // GLoabl State
  const { setCommentVisible } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  // Location
  const location = useLocation();

  return (
    <div className="home-comment-area">
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          navigation={true}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay]}
          className="comment-slider"
          ref={commentSlider}
        >
          {data.map((comment) => (
            <SwiperSlide key={comment.id}>
              {location.pathname === "/" ? (
                <div
                  className="text editor"
                  dangerouslySetInnerHTML={{
                    __html: comment.review_text,
                  }}
                />
              ) : (
                <p className="text">{comment.review_text}</p>
              )}

              <div className="personal-area">
                <div className="personal-img">
                  <img
                    src={`${process.env.REACT_APP_IMAGE}${comment.review_reviewer_image}`}
                    alt="personalImg"
                  />
                </div>
                <div className="personal-info">
                  <h4 className="person-name">
                    {comment.review_reviewer_name}
                  </h4>
                  <h6 className="job">{comment.review_reviewer_origin}</h6>
                </div>
              </div>
            </SwiperSlide>
          ))}

          <h3 className="caption">{t("hotel-comment.guest-reviews")}</h3>
          <button
            className="readMoreBtn"
            onClick={() => setCommentVisible(true)}
          >
            {t("btn.read-more")}... <img src={arrowRight} alt="icon" />
          </button>
          <div
            className="prevBtn"
            onClick={() => commentSlider.current.children[1].click()}
          >
            <img src={prev} alt="prev" />
          </div>
          <div
            className="nextBtn"
            onClick={() => commentSlider.current.children[2].click()}
          >
            <img src={next} alt="next" />
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default HomeComment;
