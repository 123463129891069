// React
import { useCallback, useEffect, useState } from "react";

// React Router Dom
import { useLocation } from "react-router-dom";

// Axios
import axios from "axios";

// Componets
import Loading from "../component/Loading";

// Language
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

// Default Img
// import defaultImg from "../assets/img/IMG-not-found.svg";
// onError={(event) => (event.target.src = defaultImg)}
// loading="lazy"
const NaftalanHistory = () => {
  // Local State
  const [naftalanData, setNaftalanData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seoData, setSeoData] = useState(null);
  // Language
  const { i18n } = useTranslation();

  // Router
  const { pathname } = useLocation();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // GEt Request
  const getNaftalanInfo = useCallback(async () => {
    setLoading(true);
    await axios
      .get(process.env.REACT_APP_NAFTALAN_HISTORY, {
        crossdomain: true,
      })
      .then((res) => {
        setNaftalanData(res.data);
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, []);

  // Rendering
  useEffect(() => {
    if (i18n.language) {
      getNaftalanInfo();
    }
  }, [getNaftalanInfo, i18n.language]);

  // function NaftalanHistory({ naftalanData }) {
  //   const handleImageError = (event) => {
  //     event.target.src = defaultImg;
  //   };
  // }
  // const handleImageError = (event) => {
  //   event.target.src = defaultImg;
  // };
  const SeoRequest = useCallback(async () => {
    await axios
      .get(
        `https://source.naftalan.biz/page-seo/read/by-pagename-string/naftalan-history`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        // setCommentData(res.data);
        console.log(res.data);
        setSeoData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    SeoRequest();
  }, [SeoRequest]);

  return (
    <>
      {
        <Helmet>
          <title>{seoData && seoData[`seo_title_${i18n.language}`]}</title>
          <meta
            name="description"
            content={seoData && seoData[`seo_description_${i18n.language}`]}
          />
        </Helmet>
      }

      <main>
        <section className="naftalan-history custom-page">
          {naftalanData && (
            <div className="container">
              <div className="naftalan-img">
                <img
                  src={`${process.env.REACT_APP_IMAGE}${naftalanData.history_image}`}
                  // onError={(event) => (event.target.src = defaultImg)}
                  // loading="lazy"
                  alt="naftalanImg"
                />
              </div>
              <div
                className="naftalan-about editor"
                dangerouslySetInnerHTML={{
                  __html: naftalanData[`history_text_${i18n.language}`],
                }}
              />
            </div>
          )}

          {loading && <Loading />}
        </section>
      </main>
    </>
  );
};

export default NaftalanHistory;
