// React
import { useCallback, useEffect, useState } from "react";

// React ROuter Dom
import { useLocation } from "react-router-dom";

// Axios
import axios from "axios";

// Components
import Loading from "../component/Loading";

import diseaseList from "../db/DiseaseIconList";

// Language
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const NaftalanDiseas = () => {
  // Local State
  const [naftalanDiseaseData, setNaftalanDiseaseData] = useState([]);
  const [naftalanData, setNaftalanData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seoData, setSeoData] = useState(null);

  // Language
  const { i18n } = useTranslation();

  // Router
  const { pathname } = useLocation();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // GEt Request
  const getNaftalanDiseaseInfo = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_NAFTALAN_DISEASE}?locale=${i18n.language}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setNaftalanDiseaseData(res.data);
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, [i18n.language]);

  // Get Requset
  const getNaftalanInfo = useCallback(async () => {
    setLoading(true);
    await axios
      .get(process.env.REACT_APP_NAFTALAN_DISEASE_HISTORY, {
        crossdomain: true,
      })
      .then((res) => {
        setNaftalanData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // Rendering
  useEffect(() => {
    if (i18n.language) {
      getNaftalanInfo();
      getNaftalanDiseaseInfo();
    }
  }, [getNaftalanDiseaseInfo, getNaftalanInfo, i18n.language]);



  const SeoRequest = useCallback(async () => {
    await axios
      .get(
        `https://source.naftalan.biz/page-seo/read/by-pagename-string/naftalan-disease`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        // setCommentData(res.data);
        console.log(res.data)
        setSeoData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    SeoRequest();
  }, [SeoRequest]);



  return (

    <>
        <Helmet>
    <title>{seoData && seoData[`seo_title_${i18n.language}`]}</title>
        <meta
          name="description"
          content={seoData && seoData[`seo_description_${i18n.language}`]}
        />
    </Helmet>
    
    <div className="naftalan-disease custom-page">
      <div className="container">
        {naftalanData && (
          <div
            className="custom-body editor"
            dangerouslySetInnerHTML={{
              __html: naftalanData[`history_text_${i18n.language}`],
            }}
          />
        )}

        {naftalanDiseaseData.length !== 0 && (
          <div className="naftalan-advantages">
            {naftalanDiseaseData.map((disease) => (
              <div className="advantages-group" key={disease.id}>
                <div className="head">
                  {diseaseList
                    .filter(
                      (icon) => icon.icon_name === disease?.disease_icon_path
                    )
                    .map((icon) => (
                      <div className="icon" key={icon.id}>
                        <img
                          src={icon.icon_img}
                          alt=""
                          className={icon.icon_name}
                        />
                      </div>
                    ))}

                  <h6 className="title">
                    {disease[`disease_hero_${i18n.language}`]}
                  </h6>
                </div>
                <div className="body">
                  <p> {disease[`disease_text_${i18n.language}`]}</p>
                </div>
                <div className="bottom">
                  <ul>
                    {disease[`disease_characteristics_${i18n.language}`]
                      ?.split("/&?/")
                      .map((character, index) => (
                        <li key={index}>{character}</li>
                      ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {loading && <Loading />}
    </div>
    </>
  );
};

export default NaftalanDiseas;
