import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { MainContext } from "../../utils/MainContext";
import { Link } from "react-router-dom";
import MobileCardHotelLoading from "../../component/home/MobileCardHotelLoading";
import { useTranslation } from "react-i18next";
import HotelCardPrimary from "../../component/home/HotelCardPrimary";

const HotelsCardsReview = ({ caption }) => {
  // Global state
  const { currentCodeName } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [dateData, setDateData] = useState({ current: "", later: "" });
  // Language
  const { t, i18n } = useTranslation();

  const getHotelData = useCallback(
    async (page_number) => {
      await axios
        .get(
          `${process.env.REACT_APP_HOTEL_FILTER}?date_from=${
            dateData.current
          }&date_to=${
            dateData.later
          }&standard_guests=${1}&standard_guest_age_1=${18}&minor_guests=${0}&currency_code=${currentCodeName}&locale=${
            i18n.language
          }&page_length=${6}&page_number=${page_number}`
        )
        .then((res) => {
          console.log(res);
          setData(res.data.page_data);
          // if (res.status === 200) {
          //   // setCar(res.data);
          //   // setLoading(false);
          // }
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
          // navigate("/error");
        });
    },
    [currentCodeName, dateData, i18n.language]
  );

  useEffect(() => {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    // Bir gün sonrasını hesapla
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate() + 1);

    const nextDayYear = nextDay.getFullYear();
    const nextDayMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
    const nextDayDay = String(nextDay.getDate()).padStart(2, "0");

    const nextDayDateString = `${nextDayYear}-${nextDayMonth}-${nextDayDay}`;

    // Durumu güncelle
    setDateData({ current: currentDateString, later: nextDayDateString });
  }, []);

  useEffect(() => {
    if (
      dateData.current &&
      dateData.later &&
      i18n.language &&
      currentCodeName
    ) {
      getHotelData(1);
    }
  }, [currentCodeName, dateData, getHotelData, i18n.language]);
  return (
    <div className="hotels-cards-review">
      <div className="container">
        <h3 className="caption">{t("general.hotel")}</h3>
        <div className="row">
          {data.length === 0
            ? Array.from({ length: 6 }, (_, index) => (
                <MobileCardHotelLoading key={index} />
              ))
            : data
                ?.slice(0, 6)
                .map((item) => (
                  <HotelCardPrimary
                    hotelData={item}
                    date={dateData}
                    adultCount={`standard_guests=${1}&standard_guest_age_1=${18}`}
                    key={item.id}
                    childrenCount={`minor_guests=${0}`}
                    specialSearch={"fs"}
                  />
                ))}
        </div>
        {data.length > 6 && (
          <div className="button-area">
            <Link
              to={`/sanatoriums/${dateData.current}/${dateData.later}/standard_guests=1&standard_guest_age_1=18/minor_guests=0/fs`}
              className="more-btn"
            >
              {t("btn.see-more")}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelsCardsReview;
