/* ------------------------------- Sweet Alert ------------------------------ */
import Swal from "sweetalert2";

export const infoAlert = (title, text, ) => {
  Swal.fire({
    title,
    text,
    showCancelButton: false,
    buttonsStyling: false,
    width: "770px",
    customClass: {
      container: "sweetAlert-container",
      title: "your-title-style",
      closeButton: "swal-close-button",
    },
    showCloseButton: true,
  });
};
