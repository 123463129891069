// React
import { useCallback, useContext, useEffect } from "react";

// React Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Context
import { MainContext } from "../../utils/MainContext";

// Component
import CalendarModal from "../search-hotel/Calendar";
import SelectHotel from "../search-hotel/SelectHotel";
import PersonalCountBase from "../search-hotel/PersonalCountBase";

// Axios
import axios from "axios";

// Alert
import { infoAlert } from "../../utils/SweetAlert";

// Language
import { useTranslation } from "react-i18next";

// Icon
import hotel from "../../assets/img/icon/hotel.svg";
import calendarIcon from "../../assets/img/icon/calendar.svg";
import personIcon from "../../assets/img/icon/person.svg";

const SearchHotelAndRezervasion = () => {
  // Global State
  const {
    currentCodeName,
    selectHotelVisible,
    setSelectHotelVisible,
    selectHotelOrRoomName,

    // Calendar
    calendarVisible,
    setCalendarVisible,
    setHandleEdit,
    startDate,
    endDate,
    dateError,
    setDateError,

    // Personal Count
    personalCountVisible,
    setPersonalCountVisible,
    setpersonalCountError,
    personalCountError,
    personalCountdata,
    setMinorGuest,
    setStandardGuestAge,
    setRenderAgain,
  } = useContext(MainContext);

  // React Router Dom
  const location = useLocation();
  const navigate = useNavigate();

  // Language
  const { t, i18n } = useTranslation();

  // Check Data For Request
  const check = () => {
    let dataCheck = true;

    // Check Personal ADult COunt
    if (personalCountdata[0].count === 0) {
      setpersonalCountError({ ...personalCountError, adult: true });
      return false;
    }

    // Check Personal Children Age
    if (personalCountdata[1]?.age.length !== 0) {
      const hasEmptyActive = personalCountdata[1].age.some((item) => {
        if (item.active === "") {
          setpersonalCountError({ ...personalCountError, children: true });
          return true;
        }
        return false;
      });

      if (hasEmptyActive) return false;
    }

    const formattedStartDate = startDate.split("-").reverse().join("-");
    const formattedEndDate = endDate.split("-").reverse().join("-");

    if (startDate === "") {
      setDateError({ ...dateError, start: true });
      return false;
    }
    if (endDate === "") {
      setDateError({ ...dateError, end: true });
      return false;
    }
    if (startDate === endDate) {
      setDateError({ start: true, end: true });
      return false;
    }

    // Request Controller
    if (dataCheck) {
      let standartLineResult = "";
      for (let i = 1; i <= personalCountdata[0].count; i++) {
        standartLineResult += `standard_guest_age_${i}=18`;
        if (i !== personalCountdata[0].count) {
          standartLineResult += "&";
        }
      }
      setStandardGuestAge(standartLineResult);

      const minorGuest = personalCountdata[1]?.age
        .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
        .join("&");
      setMinorGuest(minorGuest);

      //Specific Search
      if (selectHotelOrRoomName.name) {
        getOneHotelData(
          formattedStartDate,
          formattedEndDate,
          standartLineResult,
          minorGuest
        );
      }
      // Random Search With Date And Personal COunt
      else {
        getHotelDatas(
          formattedStartDate,
          formattedEndDate,
          standartLineResult,
          minorGuest
        );
      }
    }
  };

  const getHotelDatas = useCallback(
    async (
      formattedStartDate,
      formattedEndDate,
      standardGuestAge,
      minorGuest
    ) => {
      if (location.pathname !== "/") {
        setRenderAgain(false);
      }
      await axios
        .get(
          `${
            process.env.REACT_APP_HOTEL_FILTER
          }?date_from=${formattedStartDate}&date_to=${formattedEndDate}&standard_guests=${
            personalCountdata[0].count
          }&${standardGuestAge}&minor_guests=${personalCountdata[1].count}&${
            minorGuest && minorGuest + "&"
          }currency_code=${currentCodeName}&locale=${
            i18n.language
          }&page_length=${6}&page_number=1`,
          { crossdomain: true }
        )
        .then((res) => {
          if (res.data.page_data.length === 0) {
            alert(t("not-found.not-found"), t("not-found.not-found-hotel"));
            setPersonalCountVisible(false);
          } else if (location.pathname !== "/") {
            setRenderAgain(true);
          } else {
            let link = `/result/${formattedStartDate}/${formattedEndDate}/standard_guests=${
              personalCountdata[0].count
            }&${standardGuestAge}/minor_guests=${personalCountdata[1].count}&${
              minorGuest ? minorGuest + "&" : ""
            }/tr`;
            navigate(link);
          }
        })
        .catch((err) => {
          if (err) {
            alert(t("not-found.failed"), t("not-found.failed-request"));
          }
        });
    },
    [currentCodeName, i18n.language, location.pathname, navigate, personalCountdata, setPersonalCountVisible, setRenderAgain, t]
  );
  const getOneHotelData = useCallback(
    async (
      formattedStartDate,
      formattedEndDate,
      standardGuestAge,
      minorGuest
    ) => {
      await axios
        .get(
          `${process.env.REACT_APP_HOTEL_FILTER_ID}/${
            selectHotelOrRoomName.id
          }?date_from=${formattedStartDate}&date_to=${formattedEndDate}&standard_guests=${
            personalCountdata[0].count
          }&${standardGuestAge}&minor_guests=${personalCountdata[1].count}&${
            minorGuest && minorGuest + "&"
          }currency_code=${currentCodeName}&locale=${i18n.language}`,
          { crossdomain: true }
        )
        .then((res) => {
          if (res.data.hotel_suites.length === 0) {
            alert(t("not-found.not-found"), t("not-found.not-found-hotel"));
            setPersonalCountVisible(false);
          } else {
            let link = `/detail/${
              selectHotelOrRoomName.id
            }/${formattedStartDate}/${formattedEndDate}/standard_guests=${
              personalCountdata[0].count
            }&${standardGuestAge}/minor_guests=${personalCountdata[1].count}&${
              minorGuest ? minorGuest + "&" : ""
            }/tr`;
            navigate(link);
          }
        })
        .catch((err) => {
          if (err) {
            alert(t("not-found.failed"), t("not-found.failed-request"));
          }
        });
    },
    [currentCodeName, i18n.language, navigate, personalCountdata, selectHotelOrRoomName.id, setPersonalCountVisible, t]
  );

  const alert = (short_text, long_text) => {
    infoAlert(short_text, long_text);
  };

  // Close Modal
  useEffect(() => {
    if (location.pathname) {
      setPersonalCountVisible(false);
      setDateError({ start: false, end: false });
      setpersonalCountError({ adult: false, children: false });
    }
  }, [
    location.pathname,
    setDateError,
    setPersonalCountVisible,
    setpersonalCountError,
  ]);

  return (
    <div className="search-area">
      <div className="container">
        <form className="seacr-hotel-form home">
          {/* hotel */}
          <div className="group" onClick={() => setSelectHotelVisible(true)}>
            <div className="icon">
              <img src={hotel} alt="hotel" />
            </div>
            <div className="form-group">
              <h2 className="caption">
                {location.pathname !== "/"
                  ? t("search-rezervasion.choose-room")
                  : t("search-rezervasion.choose-hotel")}
              </h2>
              <p
                className={
                  selectHotelOrRoomName ? "detail hotelName" : "detail "
                }
              >
                {selectHotelOrRoomName.name
                  ? selectHotelOrRoomName.name
                  : location.pathname !== "/"
                  ? t("search-rezervasion.select-room")
                  : t("search-rezervasion.find-sanatorium")}
              </p>
            </div>
          </div>
          {/* calendar */}
          <div
            className="group"
            onClick={() => {
              setCalendarVisible(true);
              setHandleEdit("start");
            }}
          >
            <div className="icon">
              <img src={calendarIcon} alt="calendar" />
            </div>
            <div
              className={dateError.start ? "form-group error" : "form-group "}
            >
              <h2 className="caption">{t("search-rezervasion.check-in")}</h2>
              <p
                className={startDate !== "" ? "detail selectedDate" : "detail"}
              >
                {startDate !== ""
                  ? startDate
                  : t("search-rezervasion.add-dates")}
              </p>
            </div>
          </div>
          <div
            className="group"
            onClick={() => {
              setCalendarVisible(true);
              setHandleEdit("end");
            }}
          >
            <div className="icon">
              <img src={calendarIcon} alt="calendar" />
            </div>
            <div className={dateError.end ? "form-group error" : "form-group "}>
              <h2 className="caption">{t("search-rezervasion.check-out")}</h2>
              <p className={endDate !== "" ? "detail selectedDate" : "detail"}>
                {endDate !== "" ? endDate : t("search-rezervasion.add-dates")}
              </p>
            </div>
          </div>
          {/* person */}

          <div
            className="group personalSelect"
            onClick={() => setPersonalCountVisible(true)}
          >
            <div className="icon">
              <img src={personIcon} alt="person" />
            </div>{" "}
            <div
              className={
                personalCountError.adult || personalCountError.children
                  ? "form-group error"
                  : "form-group "
              }
            >
              <h2 className="caption">{t("search-rezervasion.who")}</h2>
              <p className="detail">
                {personalCountdata[0].count !== 0
                  ? `${
                      personalCountdata[0].count > 1
                        ? t("search-rezervasion.adults")
                        : t("search-rezervasion.adult")
                    } ${personalCountdata[0].count} ${
                      personalCountdata[1].count > 1
                        ? t("search-rezervasion.children")
                        : t("search-rezervasion.child")
                    } ${personalCountdata[1].count}`
                  : t("search-rezervasion.add-guests")}
              </p>
            </div>
            <div
              className={
                personalCountVisible
                  ? "personSelectDropDown active  "
                  : "personSelectDropDown"
              }
            >
              <PersonalCountBase />
            </div>
          </div>

          <button
            className="seacrhBtn"
            onClick={(e) => {
              e.preventDefault();
              check();
            }}
          >
            {t("search-rezervasion.search")}
          </button>
        </form>
      </div>
      {selectHotelVisible && <SelectHotel />}
      {calendarVisible && <CalendarModal />}
    </div>
  );
};

export default SearchHotelAndRezervasion;
