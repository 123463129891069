// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { useLocation } from "react-router-dom";

// Context
import { MainContext } from "../utils/MainContext";

// Section
import Hero from "./home-section/Hero";
import SearchHotelAndRezervasion from "../component/search-hotel/SearchHotelAndRezervasion";
import NaftalanDetail from "./home-section/NaftalanDetail";
// import GuestFavorite from "./home-section/GuestFavorite";
import NaftalanInfo from "./home-section/SanetoryInfo";
import HotelsCardsReview from "./home-section/HotelsCardsReview";
import HomeComment from "./home-section/HomeComment";

// Component
import CommetModal from "../component/CommetModal";

// Axios
import axios from "axios";

// Helmet
import { Helmet } from "react-helmet";

// Language
import { useTranslation } from "react-i18next";

const Home = () => {
  // Global State
  const {
    commentVisible,
    personalCountVisible,
    setPersonalCountVisible,
    setSelectDate,
    setStartDate,
    setEndDate,
    mobileType,
  } = useContext(MainContext);

  // Router
  const { pathname } = useLocation();

  // Language
  const { i18n } = useTranslation();

  const [seoData, setSeoData] = useState(null);

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Function to get today's date and the date one week later
    const calculateDates = () => {
      const today = new Date();
      const oneWeekLater = new Date(today);
      oneWeekLater.setDate(today.getDate() + 7);

      // Helper function to format date as YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };

      setSelectDate({
        start: new Date(formatDate(today)),
        end: new Date(formatDate(oneWeekLater)),
      });
      setStartDate(formatDate(today).split("-").reverse().join("-"));
      setEndDate(formatDate(oneWeekLater).split("-").reverse().join("-"));
    };

    calculateDates();
  }, []);

  // Local State
  const [commentData, setCommentData] = useState([]);

  const getCommentData = useCallback(async () => {
    await axios
      .get(
        `  ${process.env.REACT_APP_HOTEL_REVIEW_ALL}?locale=${i18n.language}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setCommentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);

  const SeoRequest = useCallback(async () => {
    await axios
      .get(
        `https://source.naftalan.biz/page-seo/read/by-pagename-string/home`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        // setCommentData(res.data);
        setSeoData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (i18n.language) {
      getCommentData();
      SeoRequest();
    }
  }, [SeoRequest, getCommentData, i18n.language]);

  return (
    <main>
      <Helmet>
        <title>{seoData && seoData[`seo_title_${i18n.language}`]}</title>
        <meta
          name="description"
          content={seoData && seoData[`seo_description_${i18n.language}`]}
        />

        {/* <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" /> */}

        <script type="text/javascript">
          {`           (function(m, o, n, t, e, r, _){
          m['__GetResponseAnalyticsObject'] = e;m[e] = m[e] || function() {(m[e].q = m[e].q || []).push(arguments)};
          r = o.createElement(n);_ = o.getElementsByTagName(n)[0];r.async = 1;r.src = t;r.setAttribute('crossorigin', 'use-credentials');_.parentNode .insertBefore(r, _);
      })(window, document, 'script', 'https://an.gr-wcon.com/script/364ce25f-bb34-4fa5-b42f-06dd69d1b530/ga.js', 'GrTracking');
`}
        </script>
      </Helmet>
      <>
        {!mobileType && <Hero />}

        {/* Search Hotel Section */}
        <SearchHotelAndRezervasion />

        <HotelsCardsReview />

        {/* Hotel && Characteristics  */}
        <NaftalanInfo />
        {/* <GuestFavorite /> */}
        {!mobileType && <NaftalanDetail />}

        {mobileType && <Hero />}
        {/* Comment Section */}
        {commentData.length !== 0 && (
          <HomeComment data={commentData?.slice(0, 8)} />
        )}
        {commentVisible && commentData.length !== 0 && (
          <CommetModal page={"home"} data={commentData} />
        )}
      </>
      {personalCountVisible && (
        <div
          className="close-personal-count-overlay"
          onClick={() => setPersonalCountVisible(false)}
        ></div>
      )}
    </main>
  );
};

export default Home;
