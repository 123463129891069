import React, { useContext } from "react";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

const HotelAbout = ({ description }) => {
  // Global State
  const { setHotelAboutVisible } = useContext(MainContext);

  // Language
  const { t } = useTranslation();

  return (
    <section className="hotel-about">
      <div className="container">
        <h6 className="caption">{t("hotel-about-modal.hotel-information")}</h6>
        <div
          className="hotel-detail editor"
          dangerouslySetInnerHTML={{
            __html: description[0].description_long,
          }}
        />
        <div className="btn-area">
          <button
            className="more-btn"
            onClick={() => setHotelAboutVisible(true)}
          >
            {t("hotel-about-modal.read-more")}...
          </button>
        </div>
      </div>
    </section>
  );
};

export default HotelAbout;
