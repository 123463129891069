import locationIcon from "../../assets/img/icon/location.svg";
import phoneIcon from "../../assets/img/icon/phone.svg";
// import linkIcon from "../../assets/img/icon/link.svg";
import mailIcon from "../../assets/img/icon/mail.svg";
import { Link } from "react-router-dom";
const HotelContactArea = ({ adress, mail, adressLink, phone, faxCode }) => {
  return (
    <div className="hotel-contact-area">
      <div className="container">
        <div className="contact-area">
          <div className="info-cart">
            <div className="icon">
              <img src={locationIcon} alt="location" />
            </div>
            <Link to={adressLink} target="_blank">
              {adress}
            </Link>
          </div>
          {/* <div className="info-cart">
            <div className="icon">
              <img src={linkIcon} alt="link" />
            </div>
              <Link>www.naftalan-booking.com</Link>
          </div> */}
          <div className="info-cart">
            <div className="icon">
              <img src={phoneIcon} alt="phone" />
            </div>
            <Link to={`tel:${phone}`}>{phone}</Link>
          </div>
          <div className="info-cart">
            <div className="icon">
              <img src={mailIcon} alt="mail" />
            </div>
            <Link to={`mailto:${mail}`}>{mail}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelContactArea;
