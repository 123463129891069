// React
import { useCallback, useContext, useEffect, useState } from "react";

// Reac Router Dom
import { Link, useLocation } from "react-router-dom";

// Language
import { useTranslation } from "react-i18next";

// Axios
import axios from "axios";

// Component
// import Loading from "../component/Loading";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastify
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Icon
import location from "../assets/img/icon/location.svg";
import phone from "../assets/img/icon/phone.svg";
import link from "../assets/img/icon/link.svg";
import mail from "../assets/img/icon/mail.svg";
import { MainContext } from "../utils/MainContext";
import { Helmet } from "react-helmet";

const Contact = () => {
  // Global state
  const { generalData } = useContext(MainContext);

  // Router
  const { pathname } = useLocation();

  const [seoData, setSeoData] = useState(null);

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Language
  const { t } = useTranslation();

  // Local state
  // const [generalData, setGeneralData] = useState(null);
  // const [loading, setLoading] = useState(false);

  // GetGeneralData
  // const settingData = useCallback(async () => {
  //   setLoading(true);
  //   await axios
  //     .get(process.env.REACT_APP_SITE_SETTINGS)
  //     .then((res) => {
  //       setGeneralData(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => err && setLoading(false));
  // }, []);

  // REndering Data
  // useEffect(() => {
  //   settingData();
  // }, [settingData]);

  // Yup schema
  const emailSchema = object({
    message: string().required().trim(),
    message_messenger_name: string().required().trim(),
    message_messenger_email: string().required().trim().email(),
  });

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  //  Send Contribute Mesagge
  const contactMessage = async (data) => {
    const body = new FormData();
    body.append("message", data.message);
    body.append("message_messenger_name", data.message_messenger_name);
    body.append("message_messenger_email", data.message_messenger_email);

    axios
      .post(process.env.REACT_APP_CONTACT_MESSAGE, body, { crossdomain: true })
      .then((res) => {
        if (res) {
          toast.info("Send Message!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const SeoRequest = useCallback(async () => {
    await axios
      .get(
        `https://source.naftalan.biz/page-seo/read/by-pagename-string/contact`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        // setCommentData(res.data);
        console.log(res.data)
        setSeoData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    SeoRequest();
  }, [SeoRequest]);

  // Language
  const {i18n}=useTranslation()

  return (
    <>
    <Helmet>
    <title>{seoData && seoData[`seo_title_${i18n.language}`]}</title>
        <meta
          name="description"
          content={seoData && seoData[`seo_description_${i18n.language}`]}
        />
    </Helmet>
      <div className="contact">
        <div className="head">
          <div className="line">
            <div className="container">
              <h3 className="caption">{t("contact-us.caption")}</h3>
            </div>
          </div>
        </div>
        <div className="container">
          <form
            className="contact-form"
            onSubmit={handleSubmit(contactMessage)}
          >
            <div className="personal-info">
              <div className="form-group">
                <label htmlFor="message_messenger_name">
                  {t("contact-us.name")}
                </label>
                <input
                  className={errors.message_messenger_name ? "error" : ""}
                  type="text"
                  name="message_messenger_name"
                  id="message_messenger_name"
                  autoComplete="on"
                  placeholder="Name"
                  {...register("message_messenger_name")}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message_messenger_email">
                  {t("contact-us.email")}
                </label>
                <input
                  className={errors.message_messenger_email ? "error" : ""}
                  type="email"
                  name="message_messenger_email"
                  id="message_messenger_email"
                  autoComplete="on"
                  placeholder={t("contact-us.email")}
                  {...register("message_messenger_email")}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">{t("contact-us.letter")}</label>
              <textarea
                className={errors.message ? "error" : ""}
                name="message"
                id="message"
                cols="30"
                rows="10"
                {...register("message")}
              ></textarea>
              <div className="submit-area">
                <button type="submit">{t("contact-us.send")}</button>
              </div>
            </div>
          </form>
          {generalData && (
            <div className="contact-area">
              <div className="info-cart">
                <div className="icon">
                  <img src={location} alt="" />
                </div>
                <Link>
                  {generalData.site_address}, {generalData.site_zip}
                  {generalData.site_city},{generalData.site_country}
                </Link>
              </div>
              <div className="info-cart">
                <div className="icon">
                  <img src={link} alt="" />
                </div>
                <Link>{generalData.site_fax}</Link>
              </div>
              <div className="info-cart">
                <div className="icon">
                  <img src={phone} alt="" />
                </div>
                <Link to={`tel:${generalData.site_phone}`}>
                  {generalData.site_phone}
                </Link>
              </div>
              <div className="info-cart">
                <div className="icon">
                  <img src={phone} alt="" />
                </div>
                <Link>{generalData.site_phone_international}</Link>
              </div>
              <div className="info-cart">
                <div className="icon">
                  <img src={mail} alt="" />
                </div>
                <Link to={`mailto:${generalData.site_email}`}>
                  {generalData.site_email}
                </Link>
              </div>
            </div>
          )}
        </div>
        {/* {loading && <Loading />} */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </div>
    </>
  );
};

export default Contact;
