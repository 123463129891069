// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { Link, NavLink } from "react-router-dom";

// Context
import { MainContext } from "../../utils/MainContext";

// Component
import DropDown from "./DropDown";
import MobileMenu from "./MobileMenu";

// Image && Icon
import burgerMenu from "../../assets/img/icon/burgerMenu.svg";
import dropDown from "../../assets/img/icon/arrow-down.svg";

// Flags
import azFlag from "../../assets/img/icon/flags/az.svg";
import enFlag from "../../assets/img/icon/flags/en.svg";
import ruFlag from "../../assets/img/icon/flags/ru.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Helmet } from "react-helmet";

const Header = () => {
  /* ------------------------------ Global State ------------------------------ */
  const {
    mobileMenu,
    setMobileMenu,
    currentCodeName,
    setCurrentCodeName,
    menuData,
    generalData,
  } = useContext(MainContext);

  /* ------------------------------- Local State ------------------------------ */

  // DropMenu Open-Close
  const [dropdownMenuData, setDropdownMenuData] = useState(null);

  // Money  List
  const [moneyTypeListVisible, setMoneyTypeListVisible] = useState(false);
  const moneyListType = [
    {
      id: 1,
      type: "AZN",
    },
    {
      id: 2,
      type: "USD",
    },
    {
      id: 3,
      type: "RUB",
    },
    {
      id: 4,
      type: "EUR",
    },
    {
      id: 5,
      type: "KZT",
    },
  ];

  // Lang List
  const [langListVisible, setLangListVisible] = useState(false);
  const langListType = [
    {
      id: 1,
      code: "az",
      lang: "Azərbaycan",
      flag: azFlag,
    },
    {
      id: 2,
      code: "en",
      lang: "English",
      flag: enFlag,
    },
    {
      id: 3,
      code: "ru",
      lang: "Русский",
      flag: ruFlag,
    },
  ];

  // Logo FaVIcon
  const [logoData, setLogoData] = useState(null);
  const [favIconData, setFavIconData] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setMoneyTypeListVisible(false);
        setLangListVisible(false);
      } else {
        return null;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Languge
  const { i18n } = useTranslation();

  // Change Language
  const selectLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Get Logo Request
  const getLogo = useCallback(async () => {
    await axios
      .get("https://source.naftalan.biz/site-logo/read")
      .then((res) => setLogoData(res.data))
      .catch((err) => console.log(err));
  }, []);

  // Get Favicon Request
  const getFavIcon = useCallback(async () => {
    await axios
      .get("https://source.naftalan.biz/site-icon/read")
      .then((res) => setFavIconData(res.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    getFavIcon();
    getLogo();
  }, [getFavIcon, getLogo]);

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          href={favIconData && `https://source.naftalan.biz${favIconData.icon}`}
          type="image/x-icon"
        />
      </Helmet>
      <header className="header">
        {generalData && (
          <div className="top">
            <div className="container">
              <div className="row">
                <Link to={`tel:${generalData.site_phone}`}>
                  {generalData.site_phone}
                </Link>
                <Link>{generalData.site_phone_international}</Link>
                <Link>{generalData.site_phone_additional}</Link>
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div className="bottom">
            {" "}
            <div className="row">
              {/* <div className={i18n.language==="ru" ? "row mobile-mode": "row"}> */}
              <div className="logo">
                <Link to="/">
                  <img
                    src={
                      logoData && `https://source.naftalan.biz${logoData.logo}`
                    }
                    alt="logo"
                  />
                </Link>
              </div>
              <nav className="navbar">
                <ul
                  className="nav-list"
                  onMouseLeave={() => setDropdownMenuData(null)}
                >
                  {menuData.map((item) => (
                    <li className="nav-item" key={item.id}>
                      <NavLink
                        to={item.url}
                        className="nav-item"
                        onMouseEnter={() =>
                          setDropdownMenuData(
                            item.dropdown ? item.dropdown : null
                          )
                        }
                      >
                        {item.menu}
                      </NavLink>
                    </li>
                  ))}
                  <DropDown dropMenuData={dropdownMenuData} />
                </ul>
              </nav>
              <div className="adition-area">
                <div
                  className={
                    moneyTypeListVisible ? "monet-type listOpen" : "monet-type "
                  }
                >
                  <div
                    className="head"
                    onClick={() =>
                      setMoneyTypeListVisible(!moneyTypeListVisible)
                    }
                  >
                    <p className="money">{currentCodeName}</p>
                    <div className="icon">
                      <img src={dropDown} alt="down" />
                    </div>
                  </div>
                  <div
                    className="list-overlay"
                    onClick={() => setMoneyTypeListVisible(false)}
                  ></div>

                  <div
                    className="body"
                    onClick={() => setMoneyTypeListVisible(false)}
                  >
                    <ul
                      className="type-list"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="seperator">
                        <span></span>
                      </div>
                      {moneyListType.map((currentCode) => (
                        <li
                          className={
                            currentCode.type === currentCodeName
                              ? "type-item active"
                              : "type-item"
                          }
                          key={currentCode.id}
                          onClick={() => {
                            setCurrentCodeName(currentCode.type);
                            setMoneyTypeListVisible(false);
                          }}
                        >
                          {currentCode.type}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div
                  className={
                    langListVisible ? "monet-type listOpen" : "monet-type "
                  }
                >
                  <div
                    className="head"
                    onClick={() => setLangListVisible(!langListVisible)}
                  >
                    <div className="flag">
                      {langListType.map(
                        (flag) =>
                          flag.code === i18n.language && (
                            <img
                              src={flag.flag}
                              alt={flag.code}
                              key={flag.code}
                            />
                          )
                      )}
                    </div>

                    <div className="icon">
                      <img src={dropDown} alt="down" />
                    </div>
                  </div>
                  <div
                    className="list-overlay"
                    onClick={() => setLangListVisible(false)}
                  ></div>

                  <div
                    className="body"
                    onClick={() => setLangListVisible(false)}
                  >
                    <ul
                      className="lang-list"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="seperator">
                        <span></span>
                      </div>
                      {langListType.map((lang, index) => (
                        <li
                          className={
                            lang.code === i18n.language
                              ? "lang-item active"
                              : "lang-item"
                          }
                          key={index}
                          onClick={() => {
                            // setCurrentLang(lang.code);
                            setLangListVisible(false);
                            selectLanguage(lang.code);
                          }}
                        >
                          <div className="flag">
                            <img src={lang.flag} alt={lang.code} />
                          </div>
                          <span className="lang-name">{lang.lang} </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div
                  className="burder-menu"
                  onClick={() => setMobileMenu(true)}
                >
                  <img src={burgerMenu} alt="burger-menu" />
                </div>
              </div>
            </div>
          </div>

          <MobileMenu />
        </div>
        <div
          className={mobileMenu ? "overlay active" : "overlay"}
          onClick={() => setMobileMenu(false)}
        ></div>
      </header>
    </>
  );
};

export default Header;
