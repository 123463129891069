const SelectHotelCardMobileLoading = () => {
  return (
    <div className="result-card-loading">
      <div className="left-side">
        <div className="hotel-img"></div>
        <div className="hotel-name"></div>
      </div>
      <div className="right-side">
        <div className="select"></div>
      </div>
    </div>
  );
};

export default SelectHotelCardMobileLoading;
