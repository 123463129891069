// React Router Dom
import { Route, Routes } from "react-router-dom";

// Context
import { AppContext } from "./utils/MainContext";

// Pages
import Home from "./pages/Home";
import HotelDetail from "./pages/HotelDetail";
import NaftalanResortAbout from "./pages/NaftalanHistory";
import NaftalanDiseas from "./pages/NaftalanDiseas";
import QuestionAnswer from "./pages/QuestionAnswer";
import Contact from "./pages/Contact";
import SearchResult from "./pages/SearchResult";

// Components
import Header from "./component/header/Header";
import Footer from "./component/Footer";
import Sanatoriums from "./pages/Sanatoriums";
import CustomPage from "./pages/CustomPage";
import InvoiceSuit from "./pages/InvoiceSuit";
// import HeaderDropDown from "./component/header/HeaderDropDown";

const App = () => {
  return (
    <AppContext>
      <Header />
      {/* <HeaderDropDown/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="detail/:id/:startDate/:endDate/:adultCount/:childrenCount/:specialSearch"
          element={<HotelDetail />}
        />

        <Route path="naftalan-history" element={<NaftalanResortAbout />} />
        <Route path="naftalan-diseas" element={<NaftalanDiseas />} />
        <Route path="question-answer" element={<QuestionAnswer />} />
        <Route path="sanatoriums/:startDateSanatorium/:endDateSanatorium/:adultCountSanatorium/:childrenCountSanatorium/:specialSearchSanatorium" element={<Sanatoriums />} />
        <Route
          path="/invoice/:hotelId/:suitId/:startDate/:endDate/:adultCount/:childrenCount/"
          element={<InvoiceSuit />}
        />

        <Route
          path="result/:startDateResult/:endDateResult/:adultCountResult/:childrenCountResult/:specialSearchResult"
          element={<SearchResult />}
        />
        <Route path="contact" element={<Contact />} />
        <Route path="custom-page/:page_name/:pageId" element={<CustomPage />} />
      </Routes>
      <Footer />
    </AppContext>
  );
};

export default App;
