// React Router Dom
import { Link } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

// Default Img
import defaultImg from "../../assets/img/IMG-not-found.svg";
import { useTranslation } from "react-i18next";

const HotelCardSlider = ({ link, hotelImg, discount }) => {
  const { t } = useTranslation();
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={-1}
      pagination={{
        clickable: true,
        dynamicBullets: true,
      }}
      modules={[Pagination]}
      className="hotel-swiper"
    >
      {(discount?.suite_applied_discounts_percentage !== undefined &&
        discount?.suite_applied_discounts_percentage.discounts.length !== 0) ||
      (discount?.suite_applied_discounts_free_night !== undefined &&
        discount?.suite_applied_discounts_free_night.discounts.length !== 0) ? (
        <span className="discount">{t("general.discount")}</span>
      ) : null}

      {hotelImg.length !== 0 ? (
        hotelImg.slice(0, 3).map((hotelPhoto) => (
          <SwiperSlide key={hotelPhoto.id}>
            <Link to={link}>
              <img
                src={`${process.env.REACT_APP_IMAGE}${hotelPhoto.image}`}
                // onError={(event) => (event.target.src = defaultImg)}
                // loading="lazy"
                alt="hotel-img"
              />
            </Link>
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>
          <Link to={link}>
            <img src={defaultImg} alt="hotel-img" />
          </Link>
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default HotelCardSlider;
