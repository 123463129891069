import axios from "axios";
import { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export const MainContext = createContext();

export const AppContext = ({ children }) => {
  // Header Money Type && Current Lang
  const [currentCodeName, setCurrentCodeName] = useState("AZN");

  // Menu && Aside Visible
  const [mobileMenu, setMobileMenu] = useState(false);

  // Select Hotel Modal
  const [selectHotelVisible, setSelectHotelVisible] = useState(false);
  const [selectHotelOrRoomName, setSelectHotelOrRoomName] = useState({
    id: "",
    name: "",
  });

  const [minorGuest, setMinorGuest] = useState("");
  const [standardGuestAge, setStandardGuestAge] = useState("");
  // Personal Count Modal
  const [personalCountVisible, setPersonalCountVisible] = useState(false);
  const [personalCountdata, setPersonalCountData] = useState([
    {
      id: 1,
      caption_en: "Adults",
      caption_ru: "Взрослые",
      caption_az: "Böyüklər",
      detail_en: "Ages 18 or above",
      detail_ru: "Возраст 18 лет и старше",
      detail_az: "18 yaş və yuxarı",
      count: 0,
    },
    {
      id: 2,
      caption_en: "Children",
      caption_ru: "Дети",
      caption_az: "Uşaqlar",
      detail_en: "Ages 0–17",
      detail_ru: "Возраст 0–17 лет",
      detail_az: "0-17 yaş",
      count: 0,
      age: [],
    },
  ]);
  const [personalCountError, setpersonalCountError] = useState({
    adult: false,
    children: false,
  });

  // Calendar Modal
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectDate, setSelectDate] = useState({ start: "", end: "" });
  const [handleEdit, setHandleEdit] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState({ start: false, end: false });

  // Select Room Modal
  const [selectRoomData, setSelectRoomData] = useState({
    id: "",
    name: "",
    total: "",
  });
  const [roomData, setRoomData] = useState(["loading"]);
  const [roomError, setRoomError] = useState(false);
  const [roomGroupELement, setRoomGroupELement] = useState([]);
  const [rotelView, setRotelView] = useState(false);
  const [roomImg, setRoomImg] = useState([]);
  const [selectInfo, setSelectInfo] = useState({
    adult: 0,
    children: 0,
    night: 0,
  });
  // Comment Modal Visible
  const [commentVisible, setCommentVisible] = useState(false);
  const [commentData, setCommentData] = useState([]);

  // Hotel About Detail
  const [hotelView, setHotelView] = useState(false);
  const [hotelAboutVisible, setHotelAboutVisible] = useState(false);
  const [hotelAboutData, setHotelAboutData] = useState([]);

  // Desktop or Mobile
  const [mobileType, setMobileType] = useState(false);

  // User Comment
  const [usercommentData, setUserCommentData] = useState(null);

  // Header Menu Datas
  const { t } = useTranslation();
  const [dateData, setDateData] = useState({ current: "", later: "" });
  const menuData = useMemo(
    () => [
      {
        id: 1,
        menu: t("header.home"),
        type: "home",
        url: "/",
      },
      {
        id: 2,
        menu: t("header.naftalan-history"),
        url: "/naftalan-history",
        type: "naftalan-history",
      },
      {
        id: 3,
        menu: t("header.sanatoriums"),
        url: `/sanatoriums/${dateData.current}/${dateData.later}/standard_guests=1&standard_guest_age_1=18/minor_guests=0/fs`,
        type: "naftalan-history",
      },
      {
        id: 4,
        menu: t("header.naftalan-diseas"),
        url: "/naftalan-diseas",
        type: "naftalan-disease",
      },
      {
        id: 5,
        menu: t("header.question-answer"),
        url: "/question-answer",
        type: "faq",
      },
      {
        id: 6,
        menu: t("header.contact"),
        type: "contact",
        url: "/contact",
      },
    ],
    [dateData, t]
  );
  const [generalData, setGeneralData] = useState(null);

  // Sanatorium && Search Result Page
  const [renderAgain, setRenderAgain] = useState(false);

  /* -------------------------------- Function Area -------------------------------- */

  useEffect(() => {
    const currentDate = new Date();

    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0");
    const currentDay = String(currentDate.getDate()).padStart(2, "0");

    const currentDateString = `${currentYear}-${currentMonth}-${currentDay}`;

    // Bir gün sonrasını hesapla
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate() + 1);

    const nextDayYear = nextDay.getFullYear();
    const nextDayMonth = String(nextDay.getMonth() + 1).padStart(2, "0");
    const nextDayDay = String(nextDay.getDate()).padStart(2, "0");

    const nextDayDateString = `${nextDayYear}-${nextDayMonth}-${nextDayDay}`;

    // Durumu güncelle
    setDateData({ current: currentDateString, later: nextDayDateString });
  }, []);

  // // Date Formater
  useEffect(() => {
    const startDate = selectDate.start;
    if (startDate !== "") {
      const day = startDate.getDate().toString().padStart(2, "0");
      const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
      const year = startDate.getFullYear().toString();

      setStartDate(`${day}-${month}-${year}`);
    }
  }, [selectDate.start, setStartDate]);

  useEffect(() => {
    const endDate = selectDate.end;
    if (endDate !== "") {
      const day = endDate.getDate().toString().padStart(2, "0");
      const month = (endDate.getMonth() + 1).toString().padStart(2, "0");
      const year = endDate.getFullYear().toString();

      setEndDate(`${day}-${month}-${year}`);
    }
  }, [selectDate.end, selectDate.start]);

  // Select Cart Type with Displey Width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMobileType(false);
      } else {
        setMobileType(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // GetGeneralData
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_SITE_SETTINGS)
      .then((res) => {
        setGeneralData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const globalStates = {
    menuData,
    /* ------------------------------ Global State ------------------------------ */
    // Header Money Type && Current Lang
    currentCodeName,
    setCurrentCodeName,
    generalData,
    setGeneralData,

    // Menu && Aside Visible
    mobileMenu,
    setMobileMenu,

    // Select Hotel Modal
    selectHotelVisible,
    setSelectHotelVisible,
    selectHotelOrRoomName,
    setSelectHotelOrRoomName,
    minorGuest,
    setMinorGuest,
    standardGuestAge,
    setStandardGuestAge,

    // Personal Count Modal
    personalCountVisible,
    setPersonalCountVisible,
    personalCountdata,
    setPersonalCountData,
    personalCountError,
    setpersonalCountError,

    // Select Room Modal
    selectRoomData,
    setSelectRoomData,
    roomData,
    setRoomData,
    roomGroupELement,
    setRoomGroupELement,
    roomError,
    setRoomError,
    rotelView,
    setRotelView,
    roomImg,
    setRoomImg,
    selectInfo,
    setSelectInfo,

    // Comment Modal
    commentVisible,
    setCommentVisible,
    commentData,
    setCommentData,

    // Hotel About Modal
    hotelView,
    setHotelView,
    hotelAboutVisible,
    setHotelAboutVisible,
    hotelAboutData,
    setHotelAboutData,

    // Calendar
    calendarVisible,
    setCalendarVisible,
    selectDate,
    setSelectDate,
    handleEdit,
    setHandleEdit,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateError,
    setDateError,

    // User Comment
    usercommentData,
    setUserCommentData,

    // Mobile or Desktop
    mobileType,
    setMobileType,
    dateData,

    // Sanatorium && Search Result Page
    renderAgain,
    setRenderAgain,
  };
  return (
    <MainContext.Provider value={globalStates}>{children}</MainContext.Provider>
  );
};
