import ambulanceFast from "../assets/img/disease-icons/AmbulanceFast.svg.svg";
import ambulance from "../assets/img/disease-icons/Ambulance.svg.svg";
import bandAids from "../assets/img/disease-icons/BandAids.svg.svg";
import bandage from "../assets/img/disease-icons/Bandage.svg.svg";
import bloodBag from "../assets/img/disease-icons/BloodBag.svg.svg";
import body from "../assets/img/disease-icons/Body.svg.svg";
import bone from "../assets/img/disease-icons/Bone.svg.svg";
import brain from "../assets/img/disease-icons/Brain.svg.svg";
import capsulePill from "../assets/img/disease-icons/Capsule&Pill.svg.svg";
import capsule from "../assets/img/disease-icons/Capsule.svg.svg";
import checkup from "../assets/img/disease-icons/Checkup.svg.svg";
import dNA from "../assets/img/disease-icons/DNA.svg.svg";
import drug from "../assets/img/disease-icons/Drug.svg.svg";
import emergencyLight1 from "../assets/img/disease-icons/Emergency Light1.svg.svg";
import emergencyLight2 from "../assets/img/disease-icons/Emergency Light2.svg.svg";
import erlenmeyerFlask from "../assets/img/disease-icons/ErlenmeyerFlask.svg.svg";
import healthcareCall from "../assets/img/disease-icons/HealthcareCall.svg.svg";
import heartRate from "../assets/img/disease-icons/HeartRate.svg.svg";
import hexagonCell from "../assets/img/disease-icons/HexagonCell.svg.svg";
import hospitalSymbol from "../assets/img/disease-icons/HospitalSymbol.svg.svg";
import hotMeal from "../assets/img/disease-icons/HotMeal.svg.svg";
import injection from "../assets/img/disease-icons/Injection.svg.svg";
import jointsBone from "../assets/img/disease-icons/JointsBone.svg.svg";
import location from "../assets/img/disease-icons/Location.svg.svg";
import lungs from "../assets/img/disease-icons/Lungs.svg.svg";
import manwithMask from "../assets/img/disease-icons/ManwithMask.svg.svg";
import mask from "../assets/img/disease-icons/Mask.svg.svg";
import medicalKit from "../assets/img/disease-icons/MedicalKit.svg.svg";
import medicalShield from "../assets/img/disease-icons/MedicalShield.svg.svg";
import microscope from "../assets/img/disease-icons/Microscope.svg.svg";
import noSmoking from "../assets/img/disease-icons/NoSmoking.svg.svg";
import nurse from "../assets/img/disease-icons/Nurse.svg.svg";
import oncology from "../assets/img/disease-icons/Oncology.svg.svg";
import overweight from "../assets/img/disease-icons/Overweight.svg.svg";
import pharmacySymbol from "../assets/img/disease-icons/PharmacySymbol.svg.svg";
import pill from "../assets/img/disease-icons/Pill.svg.svg";
import serum from "../assets/img/disease-icons/Serum.svg.svg";
import shieldVirus from "../assets/img/disease-icons/ShieldVirus.svg.svg";
import skull from "../assets/img/disease-icons/Skull.svg.svg";
import stethoscope from "../assets/img/disease-icons/Stethoscope.svg.svg";
import testTube from "../assets/img/disease-icons/TestTube.svg.svg";
import thermometer from "../assets/img/disease-icons/Thermometer.svg.svg";
import toiletPaper from "../assets/img/disease-icons/ToiletPaper.svg.svg";
import tooth from "../assets/img/disease-icons/Tooth.svg.svg";
import virus from "../assets/img/disease-icons/Virus.svg.svg";
import weightScale from "../assets/img/disease-icons/WeightScale.svg.svg";
import wheelchair from "../assets/img/disease-icons/Wheelchair.svg.svg";
import xRayChest from "../assets/img/disease-icons/X-RayChest.svg.svg";
import xRaySymbol from "../assets/img/disease-icons/X-RaySymbol.svg.svg";
import symbol from "../assets/img/disease-icons/Symbol.svg.svg";
const diseaseList = [
  {
    id: 1,
    icon_name: "ambulanceFast",
    icon_img: ambulanceFast,
  },
  {
    id: 2,
    icon_name: "ambulance",
    icon_img: ambulance,
  },
  {
    id: 3,
    icon_name: "bandAids",
    icon_img: bandAids,
  },
  {
    id: 4,
    icon_name: "bandage",
    icon_img: bandage,
  },
  {
    id: 5,
    icon_name: "bloodBag",
    icon_img: bloodBag,
  },
  {
    id: 6,
    icon_name: "body",
    icon_img: body,
  },
  {
    id: 7,
    icon_name: "bone",
    icon_img: bone,
  },
  {
    id: 8,
    icon_name: "brain",
    icon_img: brain,
  },
  {
    id: 9,
    icon_name: "capsulePill",
    icon_img: capsulePill,
  },
  {
    id: 10,
    icon_name: "capsule",
    icon_img: capsule,
  },
  {
    id: 11,
    icon_name: "checkup",
    icon_img: checkup,
  },
  {
    id: 12,
    icon_name: "dNA",
    icon_img: dNA,
  },
  {
    id: 13,
    icon_name: "drug",
    icon_img: drug,
  },
  {
    id: 14,
    icon_name: "emergencyLight1",
    icon_img: emergencyLight1,
  },
  {
    id: 15,
    icon_name: "emergencyLight2",
    icon_img: emergencyLight2,
  },
  {
    id: 16,
    icon_name: "erlenmeyerFlask",
    icon_img: erlenmeyerFlask,
  },
  {
    id: 17,
    icon_name: "healthcareCall",
    icon_img: healthcareCall,
  },
  {
    id: 18,
    icon_name: "heartRate",
    icon_img: heartRate,
  },
  {
    id: 19,
    icon_name: "hexagonCell",
    icon_img: hexagonCell,
  },
  {
    id: 20,
    icon_name: "hospitalSymbol",
    icon_img: hospitalSymbol,
  },
  {
    id: 21,
    icon_name: "hotMeal",
    icon_img: hotMeal,
  },
  {
    id: 22,
    icon_name: "injection",
    icon_img: injection,
  },
  {
    id: 23,
    icon_name: "jointsBone",
    icon_img: jointsBone,
  },
  {
    id: 24,
    icon_name: "location",
    icon_img: location,
  },
  {
    id: 25,
    icon_name: "lungs",
    icon_img: lungs,
  },
  {
    id: 26,
    icon_name: "manwithMask",
    icon_img: manwithMask,
  },
  {
    id: 27,
    icon_name: "mask",
    icon_img: mask,
  },
  {
    id: 28,
    icon_name: "medicalKit",
    icon_img: medicalKit,
  },
  {
    id: 29,
    icon_name: "medicalShield",
    icon_img: medicalShield,
  },
  {
    id: 30,
    icon_name: "microscope",
    icon_img: microscope,
  },
  {
    id: 31,
    icon_name: "noSmoking",
    icon_img: noSmoking,
  },
  {
    id: 32,
    icon_name: "nurse",
    icon_img: nurse,
  },
  {
    id: 33,
    icon_name: "oncology",
    icon_img: oncology,
  },
  {
    id: 34,
    icon_name: "overweight",
    icon_img: overweight,
  },
  {
    id: 35,
    icon_name: "pharmacySymbol",
    icon_img: pharmacySymbol,
  },
  {
    id: 36,
    icon_name: "pill",
    icon_img: pill,
  },
  {
    id: 37,
    icon_name: "serum",
    icon_img: serum,
  },
  {
    id: 38,
    icon_name: "shieldVirus",
    icon_img: shieldVirus,
  },
  {
    id: 39,
    icon_name: "skull",
    icon_img: skull,
  },
  {
    id: 40,
    icon_name: "stethoscope",
    icon_img: stethoscope,
  },
  {
    id: 41,
    icon_name: "testTube",
    icon_img: testTube,
  },
  {
    id: 42,
    icon_name: "thermometer",
    icon_img: thermometer,
  },
  {
    id: 43,
    icon_name: "toiletPaper",
    icon_img: toiletPaper,
  },
  {
    id: 44,
    icon_name: "tooth",
    icon_img: tooth,
  },
  {
    id: 45,
    icon_name: "virus",
    icon_img: virus,
  },
  {
    id: 46,
    icon_name: "symbolsymbol",
    icon_img: symbol,
  },
  {
    id: 47,
    icon_name: "weightScale",
    icon_img: weightScale,
  },
  {
    id: 48,
    icon_name: "wheelchair",
    icon_img: wheelchair,
  },
  {
    id: 49,
    icon_name: "xRayChest",
    icon_img: xRayChest,
  },
  {
    id: 50,
    icon_name: "xRaySymbol",
    icon_img: xRaySymbol,
  },
];
export default diseaseList;
