// React
import { useCallback, useContext, useEffect, useState } from "react";

// React Router Dom
import { useLocation, useParams } from "react-router-dom";

// Context
import { MainContext } from "../utils/MainContext";

import { useTranslation } from "react-i18next";

// Axios
import axios from "axios";

// Components
import HotelCardLoading from "../component/home/HotelCardLoading";
import HotelCard from "../component/home/HotelCard";
import MobileCardHotelLoading from "../component/home/MobileCardHotelLoading";
import SearchHotelAndRezervasion from "../component/search-hotel/SearchHotelAndRezervasion";
import HotelCardPrimary from "../component/home/HotelCardPrimary";

// Language

const SearchResult = () => {
  // Global state
  const {
    mobileType,
    currentCodeName,
    setPersonalCountData,
    setSelectDate,
    setStartDate,
    setEndDate,
    personalCountdata,
    standardGuestAge,
    setStandardGuestAge,
    setMinorGuest,
    startDate,
    endDate,
    renderAgain,
    setRenderAgain,

    personalCountVisible,
    setPersonalCountVisible,
  } = useContext(MainContext);

  // Prev Lang
  const [prevLang, setPrevLang] = useState("");
  const [render, setRender] = useState(false);

  // Router
  const { pathname } = useLocation();

  // Language
  const { i18n, t } = useTranslation();

  // Params
  const {
    startDateResult,
    endDateResult,
    adultCountResult,
    childrenCountResult,
  } = useParams();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
    setRender(false);
  }, [pathname]);

  // Local State
  const [data, setData] = useState({ page_data: ["loading"] });
  const [prevNumber, setPrevNumber] = useState(0);
  const [activeNumber, setActiveNumber] = useState(1);
  const [nextNumber, setNextNumber] = useState(2);
  const [selectInfo, setSelectInfo] = useState({
    adult: 0,
    children: 0,
    night: 0,
  });

  //  Change Page
  const handleChange = (step) => {
    let newPrevNumber = prevNumber;
    let newActiveNumber = activeNumber;
    let newNextNumber = nextNumber;

    if (step === "prev" && prevNumber > 0) {
      newPrevNumber = prevNumber - 1;
      newActiveNumber = activeNumber - 1;
      newNextNumber = nextNumber - 1;
    } else {
      newPrevNumber = prevNumber + 1;
      newActiveNumber = activeNumber + 1;
      newNextNumber = nextNumber + 1;
    }

    setPrevNumber(newPrevNumber);
    setActiveNumber(newActiveNumber);
    setNextNumber(newNextNumber);

    let standartLineResult = "";
    for (let i = 1; i <= personalCountdata[0].count; i++) {
      standartLineResult += `standard_guest_age_${i}=18`;
      if (i !== personalCountdata[0].count) {
        standartLineResult += "&";
      }
    }
    setStandardGuestAge(standartLineResult);

    const minorGuest = personalCountdata[1]?.age
      .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
      .join("&");
    setMinorGuest(minorGuest);

    const formattedStartDate = startDate.split("-").reverse().join("-");
    const formattedEndDate = endDate.split("-").reverse().join("-");
    setPrevLang(i18n.language);
    getHotelData(
      newActiveNumber,
      formattedStartDate,
      formattedEndDate,
      standartLineResult,
      minorGuest
    );
  };

  const calculateNights = (startDate, endDate) => {
    // Tarih formatını doğru bir şekilde `YYYY-MM-DD` formatına dönüştür
    const [startDay, startMonth, startYear] = startDate.split("-");
    const [endDay, endMonth, endYear] = endDate.split("-");

    const start = new Date(`${startYear}-${startMonth}-${startDay}`);
    const end = new Date(`${endYear}-${endMonth}-${endDay}`);

    // Gece sayısını hesapla
    const nightCount = (end - start) / (1000 * 60 * 60 * 24);

    // Eğer tarih farkı negatifse sıfır gece döndür
    return nightCount >= 0 ? nightCount : 0;
  };

  // Firstly Render while Open Page
  useEffect(() => {
    let ageArr = childrenCountResult.split("&");
    ageArr.shift();
    ageArr.pop();

    const resultArr = ageArr.map((age) => ({
      id: Math.random().toString(36).substring(7),
      active: Number(age.split("=")[1]),
    }));

    const adults = {
      id: 1,
      caption_en: "Adults",
      caption_ru: "Взрослые",
      caption_az: "Böyüklər",
      detail_en: "Ages 18 or above",
      detail_ru: "Возраст 18 лет и старше",
      detail_az: "18 yaş və yuxarı",
      count: Number(adultCountResult.split("&")[0].split("=")[1]),
    };

    const children = {
      id: 2,
      caption_en: "Children",
      caption_ru: "Дети",
      caption_az: "Uşaqlar",
      detail_en: "Ages 0–17",
      detail_ru: "Возраст 0–17 лет",
      detail_az: "0-17 yaş",
      count: Number(childrenCountResult.split("&")[0].split("=")[1]),
      age: resultArr,
    };

    setPersonalCountData((prevState) => {
      const updatedState = prevState.map((item) => {
        if (item.id === 1) {
          return { ...item, ...adults };
        } else if (item.id === 2) {
          return { ...item, ...children };
        } else {
          return item;
        }
      });
      return updatedState;
    });

    setSelectDate({
      start: new Date(startDateResult),
      end: new Date(endDateResult),
    });
    setStartDate(startDateResult.split("-").reverse().join("-"));
    setEndDate(endDateResult.split("-").reverse().join("-"));
    setStandardGuestAge(adultCountResult);
    setMinorGuest(childrenCountResult);
    const formattedStartDate = startDateResult;
    const formattedEndDate = endDateResult;

    setSelectInfo({
      adult: Number(adultCountResult.split("&")[0].split("=")[1]),
      children: Number(childrenCountResult.split("&")[0].split("=")[1]),
      night: calculateNights(
        startDateResult.split("-").reverse().join("-"),
        endDateResult.split("-").reverse().join("-")
      ),
    });

    //Specific Search
    getHotelData(
      1,
      formattedStartDate,
      formattedEndDate,
      adultCountResult,
      childrenCountResult
    );
  }, []);

  // Get Request Schema
  const getHotelData = useCallback(
    async (
      page_number,
      formattedStartDate,
      formattedEndDate,
      standardGuestAge,
      minorGuest
    ) => {
      setData({ page_data: ["loading"] });
      await axios
        .get(
          `${
            process.env.REACT_APP_HOTEL_FILTER
          }?date_from=${formattedStartDate}&date_to=${formattedEndDate}&standard_guests=${
            personalCountdata[0].count
          }&${standardGuestAge}&${
            personalCountdata[1].count === 0
              ? `minor_guests=0&`
              : `minor_guests=` +
                personalCountdata[1].count +
                "&" +
                personalCountdata[1]?.age
                  .map(
                    (item, index) =>
                      `minor_guest_age_${index + 1}=${item.active}`
                  )
                  .join("&") +
                "&"
          }currency_code=${currentCodeName}&locale=${
            i18n.language
          }&page_length=${4}&page_number=${page_number}`
        )
        .then((res) => {
          setData(res.data);

          if (render) {
            setSelectInfo({
              adult: personalCountdata[0].count,
              children: personalCountdata[1].count,
              night: calculateNights(startDate, endDate),
            });
          } else {
            setRender(true);
          }

          // setData(res.data.page_data);
          // if (res.status === 200) {
          //   // setCar(res.data);
          //   // setLoading(false);
          // }
        })
        .catch((err) => {
          console.log(err);
          // setLoading(false);
          // navigate("/error");
        });
    },
    [currentCodeName, endDate, i18n.language, personalCountdata, render, startDate]
  );

  // Render Again wWhile Change Search Data
  useEffect(() => {
    if (renderAgain) {
      setActiveNumber(1);
      setPrevNumber(0);
      setNextNumber(2);
      let standartLineResult = "";
      for (let i = 1; i <= personalCountdata[0].count; i++) {
        standartLineResult += `standard_guest_age_${i}=18`;
        if (i !== personalCountdata[0].count) {
          standartLineResult += "&";
        }
      }
      setStandardGuestAge(standartLineResult);

      const minorGuest = personalCountdata[1]?.age
        .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
        .join("&");
      setMinorGuest(minorGuest);

      const formattedStartDate = startDate.split("-").reverse().join("-");
      const formattedEndDate = endDate.split("-").reverse().join("-");

      getHotelData(
        1,
        formattedStartDate,
        formattedEndDate,
        standartLineResult,
        minorGuest
      );
      setRenderAgain(false);
    }
  }, [
    endDate,
    getHotelData,
    i18n.language,
    personalCountdata,
    renderAgain,
    setMinorGuest,
    setRenderAgain,
    setStandardGuestAge,
    startDate,
  ]);

  // Check lang
  useEffect(() => {
    if (i18n.language !== prevLang) {
      let standartLineResult = "";
      for (let i = 1; i <= personalCountdata[0].count; i++) {
        standartLineResult += `standard_guest_age_${i}=18`;
        if (i !== personalCountdata[0].count) {
          standartLineResult += "&";
        }
      }
      setStandardGuestAge(standartLineResult);

      const minorGuest = personalCountdata[1]?.age
        .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
        .join("&");
      setMinorGuest(minorGuest);

      const formattedStartDate = startDate.split("-").reverse().join("-");
      const formattedEndDate = endDate.split("-").reverse().join("-");

      getHotelData(
        1,
        formattedStartDate,
        formattedEndDate,
        standartLineResult,
        minorGuest
      );
      setRenderAgain(false);
      setPrevLang(i18n.language);
    }
  }, [
    endDate,
    getHotelData,
    i18n.language,
    personalCountdata,
    prevLang,
    setMinorGuest,
    setRenderAgain,
    setStandardGuestAge,
    startDate,
  ]);

  let minorGuestLink =
    personalCountdata[1].count === 0
      ? `minor_guests=${0}`
      : `minor_guests=${personalCountdata[1].count}` +
        "&" +
        personalCountdata[1]?.age
          .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
          .join("&") +
        "&";
  return (
    <main>
      <SearchHotelAndRezervasion />
      <div className="sanatoriums">
        <div className="container">
          <h3 className="caption">{t("search-rezervasion.result")}</h3>
          <div className="row">
            {!mobileType ? (
              <>
                {data.page_data[0] === "loading"
                  ? Array.from({ length: 6 }, (_, index) => (
                      <HotelCardLoading key={index} />
                    ))
                  : data.page_data?.slice(0, 6).map((item) => (
                      <HotelCard
                        hotelData={item}
                        date={{
                          current: startDate.split("-").reverse().join("-"),
                          later: endDate.split("-").reverse().join("-"),
                        }}
                        adultCount={
                          standardGuestAge === ""
                            ? "standard_guests=1&standard_guest_age_1=18"
                            : `standard_guests=${personalCountdata[0].count}&${standardGuestAge}`
                        }
                        key={item.id}
                        childrenCount={minorGuestLink}
                        selectInfo={selectInfo}
                        specialSearch={"fs"}
                      />
                    ))}
              </>
            ) : (
              <>
                {data.page_data[0] === "loading"
                  ? Array.from({ length: 6 }, (_, index) => (
                      <MobileCardHotelLoading key={index} />
                    ))
                  : data.page_data?.slice(0, 6).map((item) => (
                      <HotelCardPrimary
                        hotelData={item}
                        date={{
                          current: startDate.split("-").reverse().join("-"),
                          later: endDate.split("-").reverse().join("-"),
                        }}
                        adultCount={
                          standardGuestAge === ""
                            ? "standard_guests=1&standard_guest_age_1=18"
                            : `standard_guests=${personalCountdata[0].count}&${standardGuestAge}`
                        }
                        key={item.id}
                        childrenCount={minorGuestLink}
                        specialSearch={"fs"}
                      />
                    ))}
              </>
            )}
          </div>
          <div className="pagination">
            <button className="btn prev" onClick={() => handleChange("prev")}>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM17.685 19.5C18.12 19.935 18.12 20.655 17.685 21.09C17.46 21.315 17.175 21.42 16.89 21.42C16.605 21.42 16.32 21.315 16.095 21.09L10.8 15.795C10.365 15.36 10.365 14.64 10.8 14.205L16.095 8.91C16.53 8.475 17.25 8.475 17.685 8.91C18.12 9.345 18.12 10.065 17.685 10.5L13.185 15L17.685 19.5Z"
                  fill=" #AD471F"
                />
              </svg>
            </button>
            {prevNumber !== 0 && (
              <button
                className="btn prev-number"
                onClick={() => handleChange("prev")}
              >
                {prevNumber}
              </button>
            )}
            <button className="btn active">{activeNumber}</button>
            {data && data.page_has_next && (
              <button
                className="btn next-number"
                onClick={() => handleChange("next")}
              >
                {nextNumber}
              </button>
            )}

            {data && (
              <button
                className="btn next"
                disabled={!data.page_has_next}
                onClick={() => handleChange("next")}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.285 0H8.715C3.255 0 0 3.255 0 8.715V21.27C0 26.745 3.255 30 8.715 30H21.27C26.73 30 29.985 26.745 29.985 21.285V8.715C30 3.255 26.745 0 21.285 0ZM19.185 15.795L13.89 21.09C13.665 21.315 13.38 21.42 13.095 21.42C12.81 21.42 12.525 21.315 12.3 21.09C11.865 20.655 11.865 19.935 12.3 19.5L16.8 15L12.3 10.5C11.865 10.065 11.865 9.345 12.3 8.91C12.735 8.475 13.455 8.475 13.89 8.91L19.185 14.205C19.635 14.64 19.635 15.36 19.185 15.795Z"
                    fill=" #AD471F"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
      {personalCountVisible && (
        <div
          className="close-personal-count-overlay"
          onClick={() => setPersonalCountVisible(false)}
        ></div>
      )}
    </main>
  );
};

export default SearchResult;
