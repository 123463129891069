// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Context
import { MainContext } from "../utils/MainContext";

// Language
import { useTranslation } from "react-i18next";

// Axios
import axios from "axios";

// React Hook Form && yup
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

// Toastify
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// loading
import loadingBtn from "../assets/img/icon/btn-loading.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../component/Loading";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
const InvoiceSuit = () => {
  // Global State
  const { personalCountdata, currentCodeName } = useContext(MainContext);

  // Router
  const { pathname } = useLocation();

  // Scrool Set Reset
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Params
  let { hotelId, suitId, startDate, endDate, adultCount, childrenCount } =
    useParams();

  // Language
  const { t, i18n } = useTranslation();

  // Local State
  const [invoiceData, setInvoiceData] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [hotelData, setHotelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateList, setDateList] = useState([]);

  useEffect(() => {
    if (personalCountdata) {
      const count = personalCountdata[0].count + personalCountdata[1].count;
      const newDateList = Array.from({ length: count }, () => ({
        date: "",
      }));

      setDateList(newDateList);
    }
  }, [personalCountdata]);

  const handleDateChange = (date, index) => {
    const newDateList = [...dateList];
    newDateList[index].date = date;
    setDateList(newDateList);
  };

  // GetData
  const getInvoiceData = useCallback(
    async (coupon) => {
      setLoading(true);
      await axios
        .get(
          `${
            process.env.REACT_APP_INVOICE
          }/${suitId}?date_from=${startDate}&date_to=${endDate}&${adultCount}&${childrenCount}&currency_code=${currentCodeName}&locale=${
            i18n.language
          }${coupon ? `&coupon_code=${coupon}` : ""}`,
          { crossdomain: true }
        )
        .then((res) => {
          setInvoiceData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (err) {
            setLoading(false);
          }
        });
    },
    [
      adultCount,
      childrenCount,
      currentCodeName,
      endDate,
      i18n.language,
      startDate,
      suitId,
    ]
  );
  // Get Hotel Data
  const getOneHotelData = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_HOTEL_FILTER_ID}/${hotelId}?date_from=${startDate}&date_to=${endDate}&${adultCount}&${childrenCount}&currency_code=${currentCodeName}&locale=${i18n.language}`,
        { crossdomain: true }
      )
      .then((res) => {
        let hotel = res.data;
        hotel.hotel_suites = res.data.hotel_suites.find(
          (room) => room.id === Number(suitId)
        );
        setHotelData(hotel);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [
    adultCount,
    childrenCount,
    currentCodeName,
    endDate,
    hotelId,
    i18n.language,
    startDate,
    suitId,
  ]);
  // Navigate
  const navigate = useNavigate();
  // Rendering Data
  useEffect(() => {
    if (
      hotelId &&
      suitId &&
      startDate &&
      endDate &&
      adultCount &&
      childrenCount &&
      i18n.language &&
      currentCodeName
    ) {
      getInvoiceData();
      getOneHotelData();
    }
  }, [
    adultCount,
    childrenCount,
    currentCodeName,
    endDate,
    getInvoiceData,
    getOneHotelData,
    hotelId,
    i18n.language,
    startDate,
    suitId,
  ]);

  // Imput Container
  const inputs = useRef([]);
  const addToRefs = (element) => {
    if (element && !inputs.current.includes(element)) {
      inputs.current.push(element);
    }
  };

  // Reset Error
  const handleSetInp = (input) => {
    if (errorText) {
      setErrorText("");
    }
    input.style.borderColor = "";
  };

  // Schema
  const schema = object({
    reservation_contact_first_name: string().required().trim(),
    reservation_contact_last_name: string().required().trim(),
    reservation_contact_phone: string().required().trim(),
    reservation_contact_email: string().required().trim().email(),
    reservation_comment: string().required().trim(),
  });

  /* ----------------------------- React Hook Form ---------------------------- */
  const {
    register,
    handleSubmit,
    formState: { errors },

    // setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Send Request Data
  const onSubmit = (data) => {
    const allValuesFilled = inputs.current.every((item) => {
      const value = item.value.trim();
      if (value === "") {
        item.style.borderColor = "red";
        item.scrollIntoView({ behavior: "smooth", block: "nearest" });
        return false;
      } else {
        item.style.borderColor = "";
        return true;
      }
    });
    const guestspecificData = [];
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }; // Date format options

    for (let i = 0; i < inputs.current.length; i += 3) {
      const nameInput = inputs.current[i];
      const surnameInput = inputs.current[i + 1];
      dateList.map((time) => {
        const formattedDate = time.date
          .toLocaleDateString("en-GB", dateFormatOptions)
          .split("/")
          .reverse()
          .join("-"); // Format date with hyphens
        const guestData = {
          guest_name: `${nameInput.value.trim()} ${surnameInput.value.trim()}`,
          guest_date_of_birth: formattedDate,
        };
        guestspecificData.push(guestData); // Push guestData into guestspecificData array
      });

      if (allValuesFilled && sendData === false) {
        const body = {
          reservation_info: {
            reservation_associated_suite: invoiceData.id,
            reservation_from: startDate,
            reservation_until: endDate,
            reservation_total_price: invoiceData.suite_price_total,
            reservation_contact_first_name: data.reservation_contact_first_name,
            reservation_contact_last_name: data.reservation_contact_last_name,
            reservation_contact_phone: `   ${
              data.reservation_contact_phone.charAt[0] === "+"
                ? data.reservation_contact_phone
                : "+" + data.reservation_contact_phone
            } `,
            reservation_contact_email: data.reservation_contact_email,
            reservation_comment: data.reservation_comment,
            reservation_price_data_token: invoiceData.suite_price_data_token,
            reservation_currency_code: currentCodeName,
          },
          reservation_guest_info: guestspecificData,
        };
        setSendData(true);

        axios
          .post(
            `${process.env.REACT_APP_HOTEL_RESERVASION}?locale=${i18n.language}`,
            body,
            { crossdomain: true }
          )
          .then((res) => {
            if (res) {
              toast.info("Send Newletter!", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
              });

              const timer = setTimeout(() => {
                setSendData(false);
                navigate(-1);
              }, 1500);
              return () => clearTimeout(timer);
            }
          })
          .catch((err) => {
            setErrorText(err.response.data.errors);
            setSendData(false);
          });
      }
    }
  };

  return (
    <section className="invoice-suit">
      {loading && <Loading />}
      <div className="container">
        {hotelData && (
          <div className="info-hotel">
            <h1 className="hotel-name">{hotelData.hotel_name}</h1>

            <div className="discount-area">
              {hotelData.hotel_suites.suite_applied_special_price_data
                .discounted !== 0 && (
                <span className="discount-price special">
                  {
                    hotelData.hotel_suites.suite_applied_special_price_data
                      .special_price[`offer_public_name_${i18n.language}`]
                  }
                </span>
              )}

              {hotelData.hotel_suites.suite_applied_discounts_free_night
                .discounts[0] &&
                hotelData.hotel_suites.suite_applied_discounts_free_night.discounts.map(
                  (discount, index) => (
                    <span
                      className="discount-price"
                      key={index + Math.random().toString(36).substring(7)}
                    >
                      {discount[`discount_public_name_${i18n.language}`]}
                    </span>
                  )
                )}
              {hotelData.hotel_suites.suite_applied_discounts_percentage
                .discounts[0] &&
                hotelData.hotel_suites.suite_applied_discounts_percentage.discounts.map(
                  (discount, index) => (
                    <span
                      className="discount-price"
                      key={index + Math.random().toString(36).substring(7)}
                    >
                      {discount[`discount_public_name_${i18n.language}`]}
                    </span>
                  )
                )}
            </div>
            <div className="room-area">
              <div className="room-about">
                <h2 className="room-name">
                  {hotelData.hotel_suites.suite_associated_descriptions
                    .length !== 0 &&
                    hotelData.hotel_suites.suite_associated_descriptions[0]
                      .description_suite_name}
                </h2>
                <p className="room-detail-short">
                  {hotelData.hotel_suites.suite_associated_descriptions
                    .length !== 0 &&
                    hotelData.hotel_suites.suite_associated_descriptions[0]
                      ?.description_short}
                </p>
              </div>
            </div>
            {hotelData.hotel_suites.suite_associated_descriptions.length !==
              0 &&
              hotelData.hotel_suites.suite_associated_descriptions[0]
                .description_suite_note && (
                <div className="note">
                  <div className="title">{t("room.notes")}</div>
              
                  <div
                        className="note-detail editor"
                        dangerouslySetInnerHTML={{
                          __html:
                          hotelData.hotel_suites.suite_associated_descriptions[0]
                          .description_suite_note
                        }}
                      />
                </div>
              )}
          </div>
        )}
        {invoiceData && (
          <div className="invoice">
            <h4 className="caption">
              {t("reservasion.add-guest-information")}
            </h4>
            <div className="body">
              <form
                action=""
                className="reservasion-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="reservasion-info">
                  <h4 className="caption">
                    {t("reservasion.reservasion-info")}
                  </h4>
                  <div className="inp-area">
                    <div className="form-group">
                      <label htmlFor="reservation_contact_first_name">
                        {t("reservasion.name")}
                      </label>
                      <input
                        type="text"
                        className={
                          errors.reservation_contact_first_name && "error"
                        }
                        name="reservation_contact_first_name"
                        id="reservation_contact_first_name"
                        autoComplete="on"
                        placeholder={t("reservasion.name")}
                        {...register("reservation_contact_first_name", {
                          onChange: (e) => {
                            errorText !== "" && setErrorText("");
                          },
                        })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="reservation_contact_last_name">
                        {" "}
                        {t("reservasion.surname")}
                      </label>
                      <input
                        type="text"
                        className={
                          errors.reservation_contact_last_name && "error"
                        }
                        name="reservation_contact_last_name"
                        id="reservation_contact_last_name"
                        autoComplete="on"
                        placeholder={t("reservasion.surname")}
                        {...register("reservation_contact_last_name", {
                          onChange: (e) => {
                            errorText !== "" && setErrorText("");
                          },
                        })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="reservation_contact_phone">
                        {t("reservasion.phone")}
                      </label>
                      <input
                        type="number"
                        className={errors.reservation_contact_phone && "error"}
                        name="reservation_contact_phone"
                        id="reservation_contact_phone"
                        autoComplete="on"
                        placeholder="+994XXXXXXX"
                        {...register("reservation_contact_phone", {
                          onChange: (e) => {
                            errorText !== "" && setErrorText("");
                          },
                        })}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="reservation_contact_email">
                        {t("reservasion.email")}
                      </label>
                      <input
                        className={errors.reservation_contact_email && "error"}
                        type="email"
                        name="reservation_contact_email"
                        id="reservation_contact_email"
                        autoComplete="on"
                        placeholder={t("reservasion.email")}
                        {...register("reservation_contact_email", {
                          onChange: (e) => {
                            errorText !== "" && setErrorText("");
                          },
                        })}
                      />
                    </div>
                    <div className="form-group textarea">
                      <label htmlFor="reservation_comment">
                        {t("reservasion.comment")}
                      </label>
                      <textarea
                        className={errors.reservation_comment && "error"}
                        name="reservation_comment"
                        id="reservation_comment"
                        {...register("reservation_comment", {
                          onChange: (e) => {
                            errorText !== "" && setErrorText("");
                          },
                        })}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
                {Array.from(
                  {
                    length:
                      personalCountdata[0].count + personalCountdata[1].count,
                  },
                  (_, index) => (
                    <div className="guest-personal-info">
                      <h4 className="caption">
                        {index + 1}.{t("reservasion.guest")}
                      </h4>
                      <div className="inp-area">
                        <div className="form-group">
                          <label htmlFor={`name${index}`}>
                            {t("reservasion.name")}
                          </label>
                          <input
                            type="text"
                            name={`name${index}`}
                            id={`name${index}`}
                            autoComplete="on"
                            placeholder={t("reservasion.name")}
                            onChange={(e) => handleSetInp(e.target)}
                            ref={addToRefs}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor={`surname${index}`}>
                            {" "}
                            {t("reservasion.surname")}
                          </label>
                          <input
                            type="text"
                            name={`surname${index}`}
                            id={`surname${index}`}
                            autoComplete="on"
                            placeholder={t("reservasion.surname")}
                            onChange={(e) => handleSetInp(e.target)}
                            ref={addToRefs}
                          />
                        </div>
                        <div className="form-group-calendar">
                          <label htmlFor={`date${index}`}>
                            {t("reservasion.age")}
                          </label>
                          <DatePicker
                            onChange={(date) => handleDateChange(date, index)}
                            value={dateList[index].date}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
                <div className="line"></div>
                <div className="general-info">
                  <div className="inp-area">
                    <div className="form-group">
                      <label htmlFor="name">{t("reservasion.coupon")}</label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="on"
                        placeholder={t("reservasion.coupon")}
                      />
                    </div>
                    <div
                      className="btn"
                      onClick={(e) =>
                        e.target.parentElement.firstChild.lastChild.value.trim() &&
                        getInvoiceData(
                          e.target.parentElement.firstChild.lastChild.value.trim()
                        )
                      }
                    >
                      {t("reservasion.add")}{" "}
                    </div>
                  </div>
                  <div className="info-area">
                    <div className="info-group">
                      <h5 className="info-caption">{t("reservasion.date")}:</h5>{" "}
                      <p className="detail">
                        {startDate.split("-").reverse().join("-")} /{" "}
                        {endDate.split("-").reverse().join("-")}
                      </p>
                    </div>
                    <div className="info-group">
                      <h5 className="info-caption">
                        {t("reservasion.base-total")} :
                      </h5>{" "}
                      <p className="detail">
                        {invoiceData !== null &&
                          invoiceData.suite_price_base_total}{" "}
                     {currentCodeName }
                      </p>
                    </div>
                    {invoiceData !== null &&
                      (invoiceData.suite_applied_discounts_free_night.discounts
                        .length !== 0 ||
                        invoiceData.suite_applied_coupon_data.discounted !==
                          0 ||
                        invoiceData.suite_applied_discounts_percentage.discounts
                          .length !== 0 ||
                        invoiceData.suite_applied_special_price_data
                          .discounted !== 0) && <div className="line"></div>}

                    {invoiceData !== null &&
                      invoiceData.suite_applied_special_price_data.discounted !==
                        0 && (
                        <div className="info-group">
                          <h5 className="info-caption">Special Price :</h5>
                          <p className="detail">
                            -
                            {
                              invoiceData.suite_applied_special_price_data
                                .discounted
                            }{" "}
                     {currentCodeName }
                          </p>
                        </div>
                      )}
                    {invoiceData !== null &&
                      invoiceData.suite_applied_discounts_free_night.discounts
                        .length !== 0 && (
                        <div className="info-group">
                          <h5 className="info-caption">
                            {t("reservasion.discounts-free")} :
                          </h5>
                          <p className="detail">
                            -
                            {
                              invoiceData.suite_applied_discounts_free_night
                                .discounted
                            }{" "}
                    {currentCodeName }
                          </p>
                        </div>
                      )}
                    {invoiceData !== null &&
                      invoiceData.suite_applied_coupon_data.discounted !==
                        0 && (
                        <div className="info-group">
                          <h5 className="info-caption">
                            {t("reservasion.coupon")} :
                          </h5>
                          <p className="detail">
                            -{invoiceData.suite_applied_coupon_data.discounted}{" "}
                            {currentCodeName }
                          </p>
                        </div>
                      )}
                    {invoiceData !== null &&
                      invoiceData.suite_applied_discounts_percentage.discounts
                        .length !== 0 && (
                        <div className="info-group">
                          <h5 className="info-caption">
                            {" "}
                            {t("reservasion.discounts-percentage")} :
                          </h5>
                          <p className="detail">
                            -
                            {
                              invoiceData.suite_applied_discounts_percentage
                                .discounted
                            }{" "}
                         {currentCodeName }
                          </p>
                        </div>
                      )}
                    <div className="info-group">
                      <h5 className="info-caption">
                        {t("reservasion.total-price")}:
                      </h5>{" "}
                      <p className="detail">
                        {invoiceData !== null && invoiceData.suite_price_total}{" "}
                        {currentCodeName }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="btn-area">
                  <p className="error-text">{errorText} </p>
                  <button className="button">
                    {" "}
                    {sendData && <img src={loadingBtn} alt="" />}
                    {t("reservasion.send-inquiry")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
    </section>
  );
};
export default InvoiceSuit;
