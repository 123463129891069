import { useContext, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs, Zoom } from "swiper/modules";

// Icon
import close from "../../assets/img/icon/close-circle.svg";
import { MainContext } from "../../utils/MainContext";
import { useTranslation } from "react-i18next";

import defaultImg from "../../assets/img/IMG-not-found.svg";

const HotelViewModal = ({ mainPhoto, additionPhoto }) => {
  // Global State
  const { setHotelView } = useContext(MainContext);
  // Local State
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Language
  const { t } = useTranslation();

  return (
    <div
      className="hotel-view-modal"
      data-aos="fade-up"
      data-aos-duration="700"
    >
      <div className="head">
        <div className="contain">
          <button className="close-btn" onClick={() => setHotelView(false)}>
            <img src={close} alt="close" /> {t("btn.close")}
          </button>
        </div>
      </div>
      <div className="top">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          slidesPerView={1}
          spaceBetween={10}
          navigation={true}
          zoom={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs, Zoom]}
          className="mainSlider"
        >
          {additionPhoto.length !== 0 ? (
            additionPhoto?.map((photo) => (
              <SwiperSlide key={photo.id}>
                <div className="img-box swiper-zoom-container">
                  <img
                    src={`${process.env.REACT_APP_IMAGE}${photo.image}`}
                    alt="additionPhoto"
                  />
                </div>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <div className="img-box swiper-zoom-container">
                <img src={defaultImg} alt="additionPhoto" />
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <div className="bottom">
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          breakpoints={{
            300: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            400: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
            580: {
              slidesPerView: 6,
              spaceBetween: 10,
            },
            850: {
              slidesPerView: 8,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 10,
              spaceBetween: 10,
            },
          }}
          className="secondSlider"
        >
          {additionPhoto.length !== 0 ? (
            additionPhoto?.map((photo) => (
              <SwiperSlide key={photo.id}>
                <img
                  src={`${process.env.REACT_APP_IMAGE}${photo.image}`}
                  alt="additionPhoto"
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img src={defaultImg} alt="additionPhoto" />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default HotelViewModal;
