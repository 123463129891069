// React
import { useCallback, useEffect, useState } from "react";

// React Router Dom
import { useNavigate } from "react-router-dom";

// Axios
import axios from "axios";

// Loading
import Loading from "../../component/Loading";

import { useTranslation } from "react-i18next";
import diseaseList from "../../db/DiseaseIconList";
// Default Img
// import defaultImg from "../../assets/img/IMG-not-found.svg";
const NaftalanDetail = () => {
  // Local State
  const [naftalanDiseaseData, setNaftalanDiseaseData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // Language
  const { t, i18n } = useTranslation();

  // GEt Request
  const getNaftalanDiseaseInfo = useCallback(async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_NAFTALAN_DISEASE}?locale=${i18n.language}`,
        {
          crossdomain: true,
        }
      )
      .then((res) => {
        setNaftalanDiseaseData(res.data);
        setLoading(false);
      })
      .catch((err) => err && setLoading(false));
  }, [i18n.language]);

  // Rendering
  useEffect(() => {
    if (i18n.language) {
      getNaftalanDiseaseInfo();
    }
  }, [getNaftalanDiseaseInfo, i18n.language]);

  return (
    <div className="naftalan-detail">
      {naftalanDiseaseData.length !== 0 && (
        <div className="container">
          <div className="caption-area">
            <h2 className="question">{t("naftalan-diseas.question")}</h2>
            <p className="detail">{t("naftalan-diseas.detail")}</p>
          </div>
          <div className="row">
            {naftalanDiseaseData?.slice(0, 8).map((info) => (
              <div className="card" key={info.id}>
                {diseaseList
                  .filter((icon) => icon.icon_name === info?.disease_icon_path)
                  .map((icon) => (
                    <div className="head" key={icon.id}>
                      <img
                        src={icon.icon_img}
                        // onError={(event) => (event.target.src = defaultImg)}
                        // loading="lazy"
                        alt="icon"
                        className={icon.icon_name}
                      />
                    </div>
                  ))}

                <div className="body">
                  <h4 className="title">
                    {info[`disease_hero_${i18n.language}`]}
                  </h4>
                  <p className="detail">
                    {info[`disease_text_${i18n.language}`]}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="btn-area">
            <button
              className="more-btn"
              onClick={() => navigate("/naftalan-diseas")}
            >
              {t("btn.explore-more")}
            </button>
          </div>
        </div>
      )}

      {loading && <Loading />}
    </div>
  );
};

export default NaftalanDetail;
