// React
import { useCallback, useContext, useEffect, useRef, useState } from "react";

// Context
import { MainContext } from "../../utils/MainContext";

// Component
import CalendarModal from "../search-hotel/Calendar";

import axios from "axios";
import { infoAlert } from "../../utils/SweetAlert";
import { useTranslation } from "react-i18next";

// Icon
import calendarIcon from "../../assets/img/icon/calendar.svg";
import personIcon from "../../assets/img/icon/person.svg";
import roomIcon from "../../assets/img/icon/room.svg";
import PersonalCountBase from "../search-hotel/PersonalCountBase";
import { useLocation } from "react-router-dom";

const Rezervasion = ({
  id,
  startDateSpecific,
  endDateSpecific,
  adultCount,
  childrenCount,
}) => {
  // Global State
  const {
    currentCodeName,

    // Visible
    calendarVisible,
    personalCountVisible,
    setCalendarVisible,
    setPersonalCountVisible,

    // Errors
    setpersonalCountError,
    personalCountError,
    dateError,
    setDateError,
    roomError,
    // setRoomError,

    // Data
    selectRoomData,
    personalCountdata,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setMinorGuest,
    setStandardGuestAge,
    setHandleEdit,
    setSelectDate,

    // Room
    setRoomData,
    // setSelectRoomData,
    setPersonalCountData,
    setRoomGroupELement,
    setSelectInfo,
  } = useContext(MainContext);

  // Local State
  const [render, setRender] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setRender(false);
    }
  }, [location.pathname]);

  const calculateNights = (startDate, endDate) => {
    // Tarih formatını doğru bir şekilde `YYYY-MM-DD` formatına dönüştür
    const [startDay, startMonth, startYear] = startDate.split("-");
    const [endDay, endMonth, endYear] = endDate.split("-");

    const start = new Date(`${startYear}-${startMonth}-${startDay}`);
    const end = new Date(`${endYear}-${endMonth}-${endDay}`);

    // Gece sayısını hesapla
    const nightCount = (end - start) / (1000 * 60 * 60 * 24);

    // Eğer tarih farkı negatifse sıfır gece döndür
    return nightCount >= 0 ? nightCount : 0;
  };

  // Check Special Search
  useEffect(() => {
    let ageArr = childrenCount.split("&");
    ageArr.shift();
    ageArr.pop();

    const resultArr = ageArr.map((age) => ({
      id: Math.random().toString(36).substring(7),
      active: Number(age.split("=")[1]),
    }));

    setPersonalCountData([
      {
        id: 1,
        caption_en: "Adults",
        caption_ru: "Взрослые",
        caption_az: "Böyüklər",
        detail_en: "Ages 18 or above",
        detail_ru: "Возраст 18 лет и старше",
        detail_az: "18 yaş və yuxarı",
        count: Number(adultCount.split("&")[0].split("=")[1]),
      },
      {
        id: 2,
        caption_en: "Children",
        caption_ru: "Дети",
        caption_az: "Uşaqlar",
        detail_en: "Ages 0–17",
        detail_ru: "Возраст 0–17 лет",
        detail_az: "0-17 yaş",
        count: Number(childrenCount.split("&")[0].split("=")[1]),
        age: resultArr,
      },
    ]);
    setSelectInfo({
      adult: Number(adultCount.split("&")[0].split("=")[1]),
      children: Number(childrenCount.split("&")[0].split("=")[1]),
      night: calculateNights(
        startDateSpecific.split("-").reverse().join("-"),
        endDateSpecific.split("-").reverse().join("-")
      ),
    });

    setSelectDate({
      start: new Date(startDateSpecific),
      end: new Date(endDateSpecific),
    });
    setStartDate(startDateSpecific.split("-").reverse().join("-"));
    setEndDate(endDateSpecific.split("-").reverse().join("-"));
    setStandardGuestAge(adultCount);
    setMinorGuest(childrenCount);
    const formattedStartDate = startDateSpecific;
    const formattedEndDate = endDateSpecific;
    //Specific Search
    getOneHotelData(
      formattedStartDate,
      formattedEndDate,
      adultCount,
      childrenCount,
      id
    );
  }, []);

  // Language
  const { t, i18n } = useTranslation();

  // Room Group
  const element = useRef();

  // Element Contain
  useEffect(() => {
    if (element) {
      setRoomGroupELement(element);
    }
  }, [setRoomGroupELement]);

  const getOneHotelData = useCallback(
    async (
      formattedStartDate,
      formattedEndDate,
      standardGuestAge,
      minorGuest
    ) => {
      setRoomData(["loading"]);
      await axios
        .get(
          `${
            process.env.REACT_APP_HOTEL_FILTER_ID
          }/${id}?date_from=${formattedStartDate}&date_to=${formattedEndDate}&standard_guests=${
            personalCountdata[0].count
          }&${standardGuestAge}&minor_guests=${personalCountdata[1].count}&${
            minorGuest && minorGuest + "&"
          }currency_code=${currentCodeName}&locale=${i18n.language}`,
          { crossdomain: true }
        )
        .then((res) => {
          console.log(res);
          if (res.data.hotel_suites.length === 0) {
            setRoomData([]);
            alert(t("not-found.not-found"), t("not-found.not-found-hotel"));
            setPersonalCountVisible(false);
          } else {
            setRoomData(res.data.hotel_suites);
            if (render) {
              setSelectInfo({
                adult: personalCountdata[0].count,
                children: personalCountdata[1].count,
                night: calculateNights(startDate, endDate),
              });
            } else {
              setRender(true);
            }
          }
        })
        .catch((err) => {
          if (err) {
            setRoomData([]);
            alert(t("not-found.failed"), t("not-found.failed-request"));
          }
        });
    },
    [
      currentCodeName,
      endDate,
      i18n.language,
      id,
      personalCountdata,
      render,
      setPersonalCountVisible,
      setRoomData,
      setSelectInfo,
      startDate,
      t,
    ]
  );

  const checkForRoom = useCallback(() => {
    let dataCheck = true;

    // Check Personal ADult COunt
    if (personalCountdata[0].count === 0) {
      setpersonalCountError({ ...personalCountError, adult: true });
      return false;
    }

    // Check Personal Children Age
    if (personalCountdata[1]?.age.length !== 0) {
      const hasEmptyActive = personalCountdata[1].age.some((item) => {
        if (item.active === "") {
          setpersonalCountError({ ...personalCountError, children: true });
          return true;
        }
        return false;
      });

      if (hasEmptyActive) return false;
    }

    // Check Date
    const formattedStartDate = startDate.split("-").reverse().join("-");
    const formattedEndDate = endDate.split("-").reverse().join("-");

    if (startDate === "") {
      setDateError({ ...dateError, start: true });
      return false;
    }
    if (endDate === "") {
      setDateError({ ...dateError, end: true });
      return false;
    }
    if (startDate === endDate) {
      setDateError({ start: true, end: true });
      return false;
    }

    // Request Controller
    if (dataCheck) {
      let standartLineResult = "";
      for (let i = 1; i <= personalCountdata[0].count; i++) {
        standartLineResult += `standard_guest_age_${i}=18`;
        if (i !== personalCountdata[0].count) {
          standartLineResult += "&";
        }
      }
      setStandardGuestAge(standartLineResult);

      const minorGuest = personalCountdata[1]?.age
        .map((item, index) => `minor_guest_age_${index + 1}=${item.active}`)
        .join("&");
      setMinorGuest(minorGuest);

      //Specific Search
      getOneHotelData(
        formattedStartDate,
        formattedEndDate,
        standartLineResult,
        minorGuest
      );
    }
  }, [
    dateError,
    endDate,
    getOneHotelData,
    personalCountError,
    personalCountdata,
    setDateError,
    setMinorGuest,
    setStandardGuestAge,
    setpersonalCountError,
    startDate,
  ]);

  const alert = (short_text, long_text) => {
    infoAlert(short_text, long_text);
  };
  return (
    <div className="search-area hotel-detail-page">
      <div className="container">
        <div className="title">{t("search-rezervasion.booking")}</div>

        <form className="seacr-hotel-form">
          {/* room */}
          <div
            className={
              personalCountdata[0].count === 0 ||
              startDate === "" ||
              endDate === ""
                ? "group disabled  room"
                : "group   room"
            }
            onClick={() => checkForRoom()}
            ref={element}
          >
            <div className="icon">
              <img src={roomIcon} alt="room" />
            </div>
            <div className={roomError ? "form-group error" : "form-group "}>
              <h2 className="caption">{t("search-rezervasion.choose-room")}</h2>
              <p className={selectRoomData ? "detail hotelName" : "detail "}>
                {selectRoomData.name
                  ? selectRoomData.name
                  : t("search-rezervasion.select-room")}
              </p>
            </div>
          </div>
          {/* Calendar */}
          <div
            className="group"
            onClick={() => {
              setCalendarVisible(true);
              setHandleEdit("start");
            }}
          >
            <div className="icon">
              <img src={calendarIcon} alt="calendar" />
            </div>
            <div
              className={dateError.start ? "form-group error" : "form-group "}
            >
              <h2 className="caption">{t("search-rezervasion.check-in")}</h2>
              <p
                className={startDate !== "" ? "detail selectedDate" : "detail"}
              >
                {startDate !== ""
                  ? startDate
                  : t("search-rezervasion.add-dates")}
              </p>
            </div>
          </div>
          <div
            className="group"
            onClick={() => {
              setCalendarVisible(true);
              setHandleEdit("end");
            }}
          >
            <div className="icon">
              <img src={calendarIcon} alt="calendar" />
            </div>
            <div className={dateError.end ? "form-group error" : "form-group "}>
              <h2 className="caption">{t("search-rezervasion.check-out")}</h2>
              <p className={endDate !== "" ? "detail selectedDate" : "detail"}>
                {endDate !== "" ? endDate : t("search-rezervasion.add-dates")}
              </p>
            </div>
          </div>

          {/* person */}
          <div
            className="group personalSelect"
            onClick={() => setPersonalCountVisible(true)}
          >
            <div className="icon">
              <img src={personIcon} alt="personal" />
            </div>
            <div
              className={
                personalCountError.adult || personalCountError.children
                  ? "form-group error"
                  : "form-group "
              }
            >
              <h2 className="caption">{t("search-rezervasion.who")}</h2>
              <p className="detail">
                {personalCountdata[0].count !== 0
                  ? `   ${
                      personalCountdata[0].count > 1
                        ? t("search-rezervasion.adults")
                        : t("search-rezervasion.adult")
                    } ${personalCountdata[0].count} ${t()}     ${
                      personalCountdata[1].count > 1
                        ? t("search-rezervasion.children")
                        : t("search-rezervasion.child")
                    } ${personalCountdata[1].count}`
                  : t("search-rezervasion.add-guests")}
              </p>
            </div>
            <div
              className={
                personalCountVisible
                  ? "personSelectDropDown active  "
                  : "personSelectDropDown"
              }
            >
              <PersonalCountBase />
            </div>
          </div>
        </form>
      </div>
      {calendarVisible && <CalendarModal />}
    </div>
  );
};

export default Rezervasion;
