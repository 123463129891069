
import apple from "../assets/img/icon/social-media/Apple.svg.svg";
import behance from "../assets/img/icon/social-media/Behance.svg.svg";
import discord from "../assets/img/icon/social-media/Discord.svg.svg";
import dripple from "../assets/img/icon/social-media/Drippple.svg.svg";
import facebook from "../assets/img/icon/social-media/Facebook.svg.svg";
import fiqma from "../assets/img/icon/social-media/Figma.svg.svg";
import github from "../assets/img/icon/social-media/Github.svg.svg";
import google from "../assets/img/icon/social-media/Google.svg.svg";
import instagram from "../assets/img/icon/social-media/Instagram.svg.svg";
import linkedin from "../assets/img/icon/social-media/Linkedin.svg.svg";
import medium from "../assets/img/icon/social-media/Medium.svg.svg";
import pinterest from "../assets/img/icon/social-media/Pinterest.svg.svg";
import rabbit from "../assets/img/icon/social-media/Rabbit.svg.svg";
import skype from "../assets/img/icon/social-media/Skype.svg.svg";
import slack from "../assets/img/icon/social-media/Slack.svg.svg";
import snapchat from "../assets/img/icon/social-media/Snapchat.svg.svg";
import spotify from "../assets/img/icon/social-media/Spotyfy.svg.svg";
import telegram from "../assets/img/icon/social-media/Telecram.svg.svg";
import tiktok from "../assets/img/icon/social-media/TicTok.svg.svg";
import twitch from "../assets/img/icon/social-media/Twitch.svg.svg";
import twitter from "../assets/img/icon/social-media/Twitter.svg.svg";
import whatsapp from "../assets/img/icon/social-media/Whatsapp.svg.svg";
import youtube from "../assets/img/icon/social-media/Youtube.svg.svg";
import vkontakte from "../assets/img/icon/social-media/vk.svg";
import ondoklassniki from "../assets/img/icon/social-media/ok.svg";

const socialIcons = [
  {
    id: 1,
    socialPlatform: "apple",
    icon: apple,
  },
  {
    id: 2,
    socialPlatform: "behance",
    icon: behance,
  },
  {
    id: 3,
    socialPlatform: "discord",
    icon: discord,
  },
  {
    id: 4,
    socialPlatform: "dripple",
    icon: dripple,
  },
  {
    id: 5,
    socialPlatform: "fiqma",
    icon: fiqma,
  },
  {
    id: 6,
    socialPlatform: "github",
    icon: github,
  },
  {
    id: 7,
    socialPlatform: "google",
    icon: google,
  },
  {
    id: 6,
    socialPlatform: "facebook",
    icon: facebook,
  },
  {
    id: 8,
    socialPlatform: "instagram",
    icon: instagram,
  },
  {
    id: 9,
    socialPlatform: "linkedin",
    icon: linkedin,
  },
  {
    id: 10,
    socialPlatform: "medium",
    icon: medium,
  },
  {
    id: 11,
    socialPlatform: "pinterest",
    icon: pinterest,
  },
  {
    id: 12,
    socialPlatform: "rabbit",
    icon: rabbit,
  },
  {
    id: 13,
    socialPlatform: "skype",
    icon: skype,
  },
  {
    id: 14,
    socialPlatform: "slack",
    icon: slack,
  },
  {
    id: 15,
    socialPlatform: "snapchat",
    icon: snapchat,
  },
  {
    id: 16,
    socialPlatform: "spotify",
    icon: spotify,
  },
  {
    id: 17,
    socialPlatform: "telegram",
    icon: telegram,
  },
  {
    id: 18,
    socialPlatform: "tiktok",
    icon: tiktok,
  },
  {
    id: 19,
    socialPlatform: "twitch",
    icon: twitch,
  },
  {
    id: 20,
    socialPlatform: "twitter",
    icon: twitter,
  },
  {
    id: 21,
    socialPlatform: "whatsapp",
    icon: whatsapp,
  },
  {
    id: 22,
    socialPlatform: "youtube",
    icon: youtube,
  },
  {
    id: 23,
    socialPlatform: "vkontakte",
    icon: vkontakte,
  },
  {
    id: 24,
    socialPlatform: "ondoklassniki",
    icon: ondoklassniki,
  },
];
export default socialIcons;
